import styles from '../MainNav.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ComponentGroup } from 'behavior/navigation/constants';
import MobileNav from './MobileNav';
import NavMenuContext from '../NavMenuContext';
import { Helmet } from 'react-helmet';

const MainMenuBlock = ({ model, id, isDesignerMode }) => {
  const context = useMemo(() => ({ componentGroup: ComponentGroup.CascadingMainMenu }), []);
  const navId = `MobileMainNav_${id}`;

  return (
    <>
      <Helmet>
        <style>{`
          #${navId}_btn {
            background-color: ${model.toggle_BackgroundColor || 'transparent'};
            color: ${model.toggle_IconColor || 'inherit'};
          }
          #${navId} {
            color: ${model.topLevel_FontColor || 'inherit'};
          }
          #${navId} .${styles.hypTopLvl} {
            ${model.topLevel_FontFamily ? `font-family: ${model.topLevel_FontFamily}, sans-serif;` : ''}
            font-size: ${model.topLevel_FontSize || '1.5em'};
            color: ${model.topLevel_FontColor || '#000'};
            ${model.topLevel_AllCaps ? 'text-transform: uppercase;' : ''}
          }
          #${navId} .${styles.iconWrapper} {
            color: ${model.topLevel_FontColor || '#000'};
          }
          ${model.topLevel_ShowActiveItemUnderline ? `
            #${navId} .${styles.navItemRoot}.${styles.active} .${styles.hypTopLvl} > span:after {
              content: '';
              background-color: ${model.topLevel_ActiveItemUnderlineColor || '#cb2245'};
            }
          ` : ''}
          #${navId} {
            background-color: ${model.topLevel_DrawerBackgroundColor || '#fff'};
          }
          #${navId} .${styles.hypNestedLvl} {
            font-family: ${model.subLevel_FontFamily || '"Open Sans"'}, sans-serif;
            font-size: ${model.subLevel_FontSize || '1em'};
            color: ${model.subLevel_FontColor || '#333'};
            padding-left: 0;
            ${model.subLevel_AllCaps ? 'text-transform: uppercase;' : ''}
          }
          ${model.subLevel_ShowArrowInFrontOfSubItem ? `
            #${navId} .${styles.hypNestedLvl} {
              padding-left: 0.55em;
            }
            #${navId} .${styles.hypNestedLvl}:before {
              content: '› ';
            }` : ''}
        `}</style>
      </Helmet>
      <NavMenuContext.Provider value={context}>
        <MobileNav isDesignerMode={isDesignerMode} id={navId} />
      </NavMenuContext.Provider>
    </>
  );
};

MainMenuBlock.propTypes = {
  model: PropTypes.shape({
    toggle_IconColor: PropTypes.string,
    toggle_BackgroundColor: PropTypes.string,
    topLevel_FontFamily: PropTypes.string,
    topLevel_FontSize: PropTypes.string,
    topLevel_AllCaps: PropTypes.bool.isRequired,
    topLevel_FontColor: PropTypes.string,
    topLevel_ShowActiveItemUnderline: PropTypes.bool.isRequired,
    topLevel_ActiveItemUnderlineColor: PropTypes.string,
    topLevel_DrawerBackgroundColor: PropTypes.string,
    subLevel_FontFamily: PropTypes.string,
    subLevel_FontSize: PropTypes.string,
    subLevel_AllCaps: PropTypes.bool.isRequired,
    subLevel_FontColor: PropTypes.string,
    subLevel_ShowArrowInFrontOfSubItem: PropTypes.bool.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  isDesignerMode: PropTypes.bool,
};

export default MainMenuBlock;
