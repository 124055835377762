import { LOCATION_CHANGED } from 'behavior/events';
import { Epic } from 'behavior/types';
import { map, takeUntil } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { MyAccountAction, orderingExperienceLoaded, ORDERING_EXPERIENCE_LOAD } from './actions';
import { loadOrderingExperienceQuery } from './queries';
import { LoadOrderingExperienceResponse } from './types';
import { merge } from 'rxjs';

const epic: Epic<MyAccountAction> = (action$, _state$, { api }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
  const loadOrderingExperience = action$.pipe(
    ofType(ORDERING_EXPERIENCE_LOAD),
    switchMap(_ => api.graphApi<LoadOrderingExperienceResponse>(loadOrderingExperienceQuery).pipe(
      map(({ profile: { orderingExperience } }) => orderingExperienceLoaded(orderingExperience)),
      takeUntil(locationChanged$),
    )),
  );

  return merge(loadOrderingExperience); 
};

export default epic;
