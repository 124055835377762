exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ModalDrawer_scrim{position:fixed;top:0;left:0;right:0;bottom:0;background-color:var(--popup_Overlay_BackgroundColor,#000);z-index:9300000;opacity:0;visibility:hidden;transition:visibility .2s,opacity .2s ease-in}.ModalDrawer_scrim.ModalDrawer_expanded{opacity:.2;visibility:visible;transition:visibility .25s,opacity .25s ease-in}.ModalDrawer_container{position:fixed;top:0;left:0;width:100%;max-width:320px;bottom:0;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;background-color:var(--page_BackgroundColor,#fff);box-shadow:8px 0 24px rgba(0,0,0,.25);overflow:hidden;z-index:9300000;opacity:0;-webkit-transform:translateX(-100%);transform:translateX(-100%);transition:opacity .15s ease-in .05s,-webkit-transform .2s ease-in-out;transition:transform .2s ease-in-out,opacity .15s ease-in .05s;transition:transform .2s ease-in-out,opacity .15s ease-in .05s,-webkit-transform .2s ease-in-out}.ModalDrawer_container.ModalDrawer_expanded{opacity:1;-webkit-transform:translateX(0);transform:translateX(0);transition:opacity .2s ease-in .05s,-webkit-transform .25s ease-in-out;transition:transform .25s ease-in-out,opacity .2s ease-in .05s;transition:transform .25s ease-in-out,opacity .2s ease-in .05s,-webkit-transform .25s ease-in-out}.ModalDrawer_head{-webkit-flex:0 0;flex:0 0;text-align:right;line-height:0}.ModalDrawer_content{overflow:auto;-webkit-flex:1 1 auto;flex:1 1 auto}.ModalDrawer_btn-close{padding:0;margin:0;border-radius:0;border:0;background-color:transparent;color:inherit;vertical-align:bottom;width:46px;height:46px}.ModalDrawer_btn-close svg{display:block;width:24px;height:24px;margin:auto;fill:currentColor}", ""]);

// exports
exports.locals = {
	"scrim": "ModalDrawer_scrim",
	"expanded": "ModalDrawer_expanded",
	"container": "ModalDrawer_container",
	"head": "ModalDrawer_head",
	"content": "ModalDrawer_content",
	"btn-close": "ModalDrawer_btn-close",
	"btnClose": "ModalDrawer_btn-close"
};