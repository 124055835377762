import styles from './NewsletterSubscription.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SimpleText, RichText, useSimpleTexts } from 'components/sanaText';
import { subscribeToNewsletter } from 'behavior/newsletter';
import { SanaForm, FieldError } from 'components/objects/forms';
import { EmailAddressField } from 'components/objects/forms/fields';
import { SanaButton } from 'components/primitives/buttons';
import { useSuccessMessage } from './hooks';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';

const NewsletterSubscription = ({ id, model, isDesignerMode }) => {
  const dispatch = useDispatch();
  const { lastUpdated, updatedById } = useSelector(state => state.newsletter);
  const subscribedByComponent = id === updatedById;
  const [emailTitle] = useSimpleTexts(['EmailAddress']).texts;
  const onSubmit = useCallback(formData => dispatch(subscribeToNewsletter(formData, id)), []);
  const onBlur = (e, formik) => !e.currentTarget.contains(e.relatedTarget || document.activeElement) && formik.setFieldError('email', undefined);
  useSuccessMessage(lastUpdated, subscribedByComponent, 'NewsletterSubscribedSuccessfully');

  return (
    <div className={styles.container}>
      {model.showTitle &&
        <h2 className={`${styles.title} h4`}>
          <SimpleText textKey="NewsletterContainer_Title" />
        </h2>
      }
      {model.showDescription &&
        <div className={styles.description}>
          <RichText textKey="NewsletterContainer_Description" />
        </div>
      }
      <div className={styles.formWrapper}>
        <SanaForm
          name={`NewsletterInline_${id}`}
          onSubmit={onSubmit}
          shouldReset={subscribedByComponent}
          className={styles.form}
          validateOnBlur={false}
          onBlur={onBlur}
        >
          <div className={styles.formFieldWrapper}>
            <EmailAddressField
              fieldName="email"
              fieldTitle={emailTitle}
              placeholder={emailTitle}
              maxLength={80}
              size="12"
              required
              validation={{ required: true }}
              disabled={isDesignerMode}
            />
          </div>
          <SanaButton textKey="Subscribe" type="submit" className={`${btnStyles.btnSmall} ${styles.btnSubmit} ${isDesignerMode && styles.vdMode}`} disabled={isDesignerMode} />
          <div className={styles.validation}>
            <FieldError fieldName="email" />
          </div>
        </SanaForm>
      </div>
    </div>
  );
};

NewsletterSubscription.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.shape({
    showTitle: PropTypes.bool.isRequired,
    showDescription: PropTypes.bool.isRequired,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

// eslint-disable-next-line react/no-multi-comp
const NewsletterSubscriptionBlock = ({ isDesignerMode, ...props }) => {
  const [canSubscribe] = useHasAbilities(AbilityTo.SubscribeToNewsletter);

  if (!isDesignerMode && !canSubscribe)
    return null;

  return <NewsletterSubscription isDesignerMode={isDesignerMode} {...props} />;
};

NewsletterSubscriptionBlock.propTypes = {
  model: PropTypes.shape({
    showTitle: PropTypes.bool.isRequired,
    showDescription: PropTypes.bool.isRequired,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

export default memo(NewsletterSubscriptionBlock);
