export default Object.freeze([
  {
    columns: [
      {
        contentBlocks: [
          {
            id: 'np5ihx6vr',
            model: {
              navigationGroupCode: 'SITEMAP',
              headingLinks_ThemeFontSize: 'Large',
              headingLinks_AllCaps: false,
              headingLinks_FontColor: '#4c4c4c',
              headingLinks_HoverFontColor: '#333',
              links_ThemeFontSize: 'Regular',
              links_AllCaps: false,
              links_FontColor: '#4c4c4c',
              links_HoverFontColor: '#333',
              links_ShowArrowInFront: false,
            },
            name: 'LinkMenu',
          },
        ],
        id: 'eqxc3b7es',
      },
    ],
    id: 'clk26kktl',
    spacing: {
      margin: null,
      padding: '24px 0px 12px 0px',
    },
  }, {
    columns: [
      {
        contentBlocks: [
          {
            id: 'j1zkmvo3a',
            model: {
              showTitle: true,
              showDescription: true,
            },
            name: 'NewsletterSubscription',
          },
        ],
        id: 'vuuqorlh4',
      },
    ],
    id: '18stskr2a',
    spacing: {
      margin: null,
      padding: '12px 0px',
    },
  }, {
    columns: [
      {
        contentBlocks: [
          {
            horizontalAlignment: 'CENTER',
            id: '4g5db42kb',
            model: {
              navigationGroupCode: 'FOOTER',
              headingLinks_ThemeFontSize: 'Regular',
              headingLinks_AllCaps: false,
              headingLinks_HoverFontColor: '#333',
              links_ThemeFontSize: 'Regular',
              links_AllCaps: false,
              links_HoverFontColor: '#333',
              links_ShowArrowInFront: false,
            },
            name: 'LinkMenu',
          },
        ],
        id: '6asqh0esl',
      },
    ],
    id: 'sme3xuogw',
    spacing: {
      margin: null,
      padding: '12px 0px',
    },
  }, {
    columns: [
      {
        contentBlocks: [
          {
            horizontalAlignment: 'CENTER',
            id: 'jxn9hp79n',
            name: 'CopyrightText',
          },
        ],
        id: '9gi8uu80b',
      },
    ],
    id: '7rok63kk5',
    spacing: {
      margin: null,
      padding: '12px 0px',
    },
  },  
]);
