import styles from './StyleWrapper.module.scss';
import { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useResponsiveBreakpoints } from 'utils/layout';
import { ContentHorizontalAlignment } from './constants';
import { joinClasses, getMinValue } from 'utils/helpers';

const StyleWrapper = forwardRef(({
  children,
  spacing: { margin, padding },
  minHeight,
  minWidth,
  horizontalAlignment,
  contentOrientation,
  stretchHeight,
  stretchWidth,
}, ref) => {
  const { xs, sm, md } = useResponsiveBreakpoints();
  const actualMinHeight = useMemo(() => getMinValue(minHeight, xs, sm, md), [xs, sm, md, minHeight]);
  const actualMinWidth = useMemo(() => getMinValue(minWidth, xs, sm, md), [xs, sm, md, minWidth]);
  const isHorizontalOrientation = contentOrientation === 'HORIZONTAL';

  const style = {
    padding: padding || null,
    margin: margin || null,
    minHeight: actualMinHeight,
    minWidth: actualMinWidth,
    textAlign: ContentHorizontalAlignment[horizontalAlignment] || null,
  };

  if (margin) {
    if (isHorizontalOrientation)
      style.height = stretchHeight ? getDimensionString(margin, true) : null;
    else
      style.width = stretchWidth ? getDimensionString(margin) : null;
  }

  return (
    <div
      ref={ref}
      className={joinClasses(
        styles.wrapper,
        stretchHeight && (isHorizontalOrientation ? styles.stretchHeight : styles.flexStretchHeight),
        stretchWidth && (isHorizontalOrientation ? styles.flexStretchWidth : styles.stretchWidth),
      )}
      style={style}
    >{children}</div>
  );
});

const PropTypesMinValue = PropTypes.shape({
  mobile: PropTypes.string,
  tablet: PropTypes.string,
  desktop: PropTypes.string,
}).isRequired;

StyleWrapper.propTypes = {
  children: PropTypes.any,
  spacing: PropTypes.shape({
    margin: PropTypes.string,
    padding: PropTypes.string,
  }),
  minHeight: PropTypesMinValue,
  minWidth: PropTypesMinValue,
  horizontalAlignment: PropTypes.oneOf(Object.keys(ContentHorizontalAlignment)),
  contentOrientation: PropTypes.string,
  stretchHeight: PropTypes.bool,
  stretchWidth: PropTypes.bool,
};

export default StyleWrapper;

function getDimensionString(marginString, sideIndents = false) {
  const filterFn = (_, index) => sideIndents ? index % 2 === 0 : index % 2 !== 0;
  const [value1, value2] = marginString.split(/\s/).filter(filterFn).map(m => parseInt(m, 10));
  return value1 || value2 ? `calc(100% - ${value1 + value2}px)` : null;
}
