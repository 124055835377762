exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OpenerButton_btn-menu-opener{height:100%;min-width:60px;min-height:60px;padding:8px;font-size:24px;background-color:transparent;color:currentColor;cursor:pointer;border:0;display:-webkit-inline-flex;display:inline-flex;vertical-align:bottom;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}.OpenerButton_icon{max-width:.3em;max-height:.25em}", ""]);

// exports
exports.locals = {
	"btn-menu-opener": "OpenerButton_btn-menu-opener",
	"btnMenuOpener": "OpenerButton_btn-menu-opener",
	"icon": "OpenerButton_icon"
};