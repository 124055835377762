export default Object.freeze([
  {
    columns: [
      {
        colspan: {
          lg: 9,
          md: 9,
          sm: 9,
        },
        contentBlocks: [
          {
            id: '6iqm9p38q',
            model: {
              navigationGroupCode: 'SITEMAP',
              headingLinks_ThemeFontSize: 'Large',
              headingLinks_AllCaps: false,
              headingLinks_FontColor: '#4c4c4c',
              headingLinks_HoverFontColor: '#333',
              links_ThemeFontSize: 'Regular',
              links_AllCaps: false,
              links_FontColor: '#4c4c4c',
              links_HoverFontColor: '#333',
              links_ShowArrowInFront: false,
            },
            name: 'LinkMenu',
          },
        ],
        horizontalAlignment: 'LEFT',
        id: 'e3doozupi',
        padding: '0px 0px 20px',
      },
      {
        colspan: {
          lg: 3,
          md: 3,
          sm: 3,
        },
        contentBlocks: [
          {
            id: 'hcve8x11i',
            model: {
              showTitle: true,
              showDescription: true,
            },
            name: 'NewsletterSubscription',
          },
        ],
        id: 'w7e3ixddk',
        padding: '0px 0px 20px',
      },
    ],
    id: '3p3094fp1',
    spacing: {
      margin: null,
      padding: '28px 0px 0px',
    },
  },
  {
    columns: [
      {
        colspan: {
          lg: 6,
          md: 6,
          sm: 6,
        },
        contentBlocks: [
          {
            id: 'i9m1hapkv',
            name: 'CopyrightText',
          },
        ],
        contentOrientation: 'HORIZONTAL',
        verticalAlignment: 'BOTTOM',
        id: '3piflvqp7',
        padding: '10px 0px',
      },
      {
        colspan: {
          lg: 6,
          md: 6,
          sm: 6,
        },
        contentBlocks: [
          {
            horizontalAlignment: 'RIGHT',
            id: '6tcd7uu49',
            model: {
              navigationGroupCode: 'FOOTER',
              headingLinks_ThemeFontSize: 'Regular',
              headingLinks_AllCaps: false,
              headingLinks_HoverFontColor: '#333',
              links_ThemeFontSize: 'Regular',
              links_AllCaps: false,
              links_HoverFontColor: '#333',
              links_ShowArrowInFront: false,
            },
            name: 'LinkMenu',
          },
        ],
        horizontalAlignment: 'RIGHT',
        id: 'y6honj0qb',
        padding: '10px 0px',
      },
    ],
    id: 'dy1y6asgm',
    spacing: {
      margin: null,
      padding: '10px 0px',
    },
  },
]);
