import { tap } from 'rxjs/operators';
import { createSystemPageHandler } from '../system';
import { SalesAgreementStatus } from 'behavior/salesAgreements';

const systemHandler = createSystemPageHandler();

export default function salesAgreementsHandler(routeData, $state, dependencies) {
  const search = routeData.params?.search;
  const activeOnly = routeData.params?.activeOnly;
  const previewToken = routeData.params?.previewToken;

  return systemHandler(routeData, $state, dependencies)
    .pipe(
      tap(data => {
        if (data.page) {
          data.page.search = search;
          data.page.activeOnly = activeOnly == null || activeOnly;

          if (previewToken) {
            data.page.agreements = {
              items: Array.from(Array(5)).map((_, index) => ({
                id: index.toString(),
                status: SalesAgreementStatus.Active,
                url: '',
                effectiveDate: '-',
                expirationDate: '-',
              })),
              loadedLength: 5,
            };
          }
        }
      }),
    );
}