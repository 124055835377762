import { ForgotPasswordAction, FORGOT_PASSWORD_REQUESTED, received } from './actions';
import { forgotMutation } from './queries';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, mergeMap } from 'rxjs/operators';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { resetCaptcha } from 'behavior/captcha';
import { Epic } from 'behavior/types';

const epic: Epic<ForgotPasswordAction> = (action$, _state$, { api, logger }) => action$.pipe(
  ofType(FORGOT_PASSWORD_REQUESTED),
  switchMap(action => api.graphApi(forgotMutation, { input: action.payload }).pipe(
    mergeMap(_ => [resetCaptcha(), received()]),
    catchApiErrorWithToast(undefined, of(resetCaptcha())),
    retryWithToast(action$, logger, () => of(resetCaptcha())),
  )),
);

export default epic;
