import PropTypes from 'prop-types';
import DesktopLanguageSelectorBase from './DesktopLanguageSelectorBase';

const DesktopLanguageSelectorBlock = ({ model, isDesignerMode, id }) => (
  <DesktopLanguageSelectorBase options={model} isDesignerMode={isDesignerMode} elementId={id} />
);

DesktopLanguageSelectorBlock.propTypes = {
  model: PropTypes.shape({
    toggle_Presentation: PropTypes.number.isRequired,
    toggle_FontFamily: PropTypes.string,
    toggle_FontSize: PropTypes.string.isRequired,
    toggle_AllCaps: PropTypes.bool.isRequired,
    toggle_FontColor: PropTypes.string,
    content_ShowFlags: PropTypes.bool.isRequired,
    content_AllCaps: PropTypes.bool.isRequired,
    content_ShowExpandIcon: PropTypes.bool.isRequired,
    content_BackgroundColor: PropTypes.string,
    content_ItemHoverBackgroundColor: PropTypes.string,
    content_BorderColor: PropTypes.string,
    content_ItemFontSize: PropTypes.string.isRequired,
    content_ItemFontColor: PropTypes.string,
    content_ItemHoverFontColor: PropTypes.string,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
  id: PropTypes.string,
};

export default DesktopLanguageSelectorBlock;
