import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { merge } from 'rxjs';
import { switchMap, mapTo } from 'rxjs/operators';
import { subscribeMutation, unsubscribeMutation } from './queries';
import { NEWSLETTER_SUBSCRIBE, NEWSLETTER_UNSUBSCRIBE, updateNewsletterSubscription, NewsletterAction } from './actions';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';

const epic: Epic<NewsletterAction> = function newsletterEpic(action$, _, { api, logger }) {
  const subscribe$ = action$.pipe(
    ofType(NEWSLETTER_SUBSCRIBE),
    switchMap(({ payload: { input, updatedById } }) => api.graphApi(subscribeMutation, { input }).pipe(
      mapTo(updateNewsletterSubscription(updatedById, input.email)),
      catchApiErrorWithToast(),
      retryWithToast(action$, logger),
    )),
  );

  const unsubscribe$ = action$.pipe(
    ofType(NEWSLETTER_UNSUBSCRIBE),
    switchMap(({ payload: { input, updatedById } }) => api.graphApi(unsubscribeMutation, { input }).pipe(
      mapTo(updateNewsletterSubscription(updatedById, input.email)),
      catchApiErrorWithToast(),
      retryWithToast(action$, logger),
    )),
  );

  return merge(subscribe$, unsubscribe$);
};

export default epic;
