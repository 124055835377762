import styles from '../MainNav.module.scss';
import { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useOnPageChanged } from 'utils/hooks';
import { useSanaTexts } from 'components/sanaText';
import { stripHtmlTagsAndFormatting } from 'utils/helpers';
import OpenerButton from '../OpenerButton';
import MainNav from '../MainNav';
import NavItem from './NavItem';
import { ModalDrawer } from 'components/primitives/modalDrawer';
import AccordionStateProvider from './AccordionStateProvider';

const textsToLoad = ['Aria_MobileTopMenu_ToggleButton'];

const MobileNav = ({ id, isDesignerMode }) => {
  const [expanded, setExpanded] = useState(false);
  const [ariaTopMenuToggleButtonText] = useSanaTexts(textsToLoad, stripHtmlTagsAndFormatting).texts;
  const handleSkipLinkClick = useCallback(() => setExpanded(true), []);
  const drawerId = id + '_drawer';
  
  useOnPageChanged(() => setExpanded(false));

  return (
    <>
      <OpenerButton
        onClick={() => setExpanded(!expanded)}
        aria-label={ariaTopMenuToggleButtonText}
        aria-pressed={expanded}
        aria-controls={isDesignerMode ? null : drawerId}
        id={`${id}_btn`}
      />
      {!isDesignerMode &&
        <ModalDrawer
          expanded={expanded}
          onClose={() => setExpanded(!expanded)}
          id={drawerId}
          className={styles.drawer}
        >
          <AccordionStateProvider isExpanded={expanded}>
            <MainNav
              NavItemComponent={NavItem}
              navClass={styles.accordion}
              handleSkipLinkClick={handleSkipLinkClick}
              id={id}
            />
          </AccordionStateProvider>
        </ModalDrawer>
      }
    </>
  );
};

MobileNav.propTypes = {
  id: PropTypes.string.isRequired,
  isDesignerMode: PropTypes.bool,
};

export default memo(MobileNav);
