import {
  DOCUMENT_LINES_RECEIVED,
  DOCUMENT_REORDER_REQUESTED,
  DOCUMENT_REORDER_RECEIVED,
  QUOTE_STOCK_RECEIVED,
  QUOTE_PROMOTION_RESULT_RECEIVED,
  DOCUMENT_EDIT_REQUESTED,
  DOCUMENT_EDIT_RECEIVED,
  ORDER_AUTHORIZATION_RESULT_RECEIVED,
  DocumentAction,
  ReorderReceived,
  QuoteStockReceived,
  QuotePromotionResultReceived,
  EditReceived,
  OrderAuthResultReceived,
  DocumentLinesReceived,
  SET_INVOICE_PAYMENT_AMOUNT,
  SetInvoicePaymentAmount,
} from './actions';
import { createReducer } from 'utils/redux';
import { isQuoteInStock } from './helpers';
import { DocumentType, EditResult, ReorderResult } from 'behavior/documents';
import type { Document, PromotionErrorResult, PromotionResult } from './types';

type State = {
  document: Document;
  reorderResult?: ReorderResult | null;
  editResult?: EditResult | null;
  promotionResult?: PromotionResult | PromotionErrorResult;
};

export default createReducer<State, DocumentAction>(null as unknown as State, {
  [DOCUMENT_LINES_RECEIVED]: onDocumentLinesReceived,
  [DOCUMENT_REORDER_REQUESTED]: onReorder,
  [DOCUMENT_REORDER_RECEIVED]: onReorderReceived,
  [QUOTE_STOCK_RECEIVED]: onQuoteStockReceived,
  [QUOTE_PROMOTION_RESULT_RECEIVED]: onQuotePromotionResultReceived,
  [DOCUMENT_EDIT_REQUESTED]: onEdit,
  [DOCUMENT_EDIT_RECEIVED]: onEditReceived,
  [ORDER_AUTHORIZATION_RESULT_RECEIVED]: onOrderAuthorizationResultReceived,
  [SET_INVOICE_PAYMENT_AMOUNT]: onSetInvoicePaymentAmount,
});

function onDocumentLinesReceived(state: State, action: DocumentLinesReceived) {
  const document = {
    ...state.document,
    ...action.payload.documentLines,
  } as Document;

  if (document && document.documentType === DocumentType.Quote)
    return {
      ...state,
      document: { ...document, hasStock: isQuoteInStock(document) },
    };

  return {
    ...state,
    document,
  };
}

function onReorder(state: State) {
  return { ...state, reorderResult: null };
}

function onReorderReceived(state: State, action: ReorderReceived) {
  const { result: reorderResult } = action.payload;

  return {
    ...state,
    reorderResult,
  };
}

function onEdit(state: State) {
  return { ...state, editResult: null };
}

function onEditReceived(state: State, action: EditReceived) {
  const { result: editResult } = action.payload;

  return {
    ...state,
    editResult,
  };
}

function onQuoteStockReceived(state: State, action: QuoteStockReceived) {
  const { quote } = action.payload;

  if (!quote)
    return state;

  return {
    ...state,
    document: {
      ...state.document,
      hasStock: isQuoteInStock(quote, true),
    },
  };
}

function onQuotePromotionResultReceived(state: State, action: QuotePromotionResultReceived) {
  return {
    ...state,
    promotionResult: action.payload,
  };
}

function onOrderAuthorizationResultReceived(state: State, action: OrderAuthResultReceived) {
  const { authorizationActionResultStatus, authorizationInfo } = action.payload;
  return {
    ...state,
    document: {
      ...state.document,
      authorizationInfo,
      authorizationActionResultStatus,
    },
  };
}

function onSetInvoicePaymentAmount(state: State, action: SetInvoicePaymentAmount) {
  const { paymentAmount } = action.payload;
  return {
    ...state,
    document: {
      ...state.document,
      paymentAmount,
    },
  };
}
