import styles from './LanguageSelector.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import useLanguageSelectorBehavior from './useLanguageSelectorBehavior';
import { Dropdown } from 'components/primitives/dropdown';
import Header from './Header';
import Body from './Body';
import { Placeholder } from 'components/primitives/placeholders';
import { getThemeFontSizeClassName } from 'components/theme';
import { Helmet } from 'react-helmet';
import { mobileSafariMediaQuery } from 'utils/detections';
import { mousePointerClassName } from 'components/detection';

const DesktopLanguageSelectorBase = ({ options, isDesignerMode, elementId }) => {
  const {
    languagesReady,
    selectedLanguage,
    sortedLanguages,
    alternateUrls,
    loadAlternateUrls,
  } = useLanguageSelectorBehavior();

  if (!languagesReady || (sortedLanguages.length <= 1 && !isDesignerMode))
    return null;

  const languageSelectorId = `DesktopLanguageSelector_${elementId}`;
  const toggleFontSizeClassName = getThemeFontSizeClassName(options.toggle_FontSize);
  const dropdownItemFontSizeClassName = getThemeFontSizeClassName(options.content_ItemFontSize);

  return (
    <>
      <Helmet>
        <style>{`
          ${options.toggle_FontFamily || options.toggle_FontColor || options.toggle_AllCaps ? `
            #${languageSelectorId} .dropdown-header {
              ${options.toggle_FontFamily ? `font-family: ${options.toggle_FontFamily}, sans-serif;` : ''}
              ${options.toggle_FontColor ? `color: ${options.toggle_FontColor};` : ''}
              ${options.toggle_AllCaps ? 'text-transform: uppercase;' : ''}
            }
          ` : ''}
          #${languageSelectorId} .${styles.item} {
            color: ${options.content_ItemFontColor || '#333'};
          }
          #${languageSelectorId}.dropdown-opened,
          #${languageSelectorId}.dropdown .dropdown-body {
            background-color: ${options.content_BackgroundColor || '#fff'} !important;
            border-color: ${options.content_BorderColor || '#e6e6e6'} !important;
          }
          ${options.content_AllCaps ? `
            #${languageSelectorId}.dropdown .dropdown-body {
              ${options.content_AllCaps ? 'text-transform: uppercase;' : ''}
            }
          ` : ''}
          #${languageSelectorId}.dropdown-opened .dropdown-header {
            color: ${options.content_ItemFontColor || '#333'};
          }
          html.${mousePointerClassName} #${languageSelectorId} .${styles.item}:hover {
            background-color: ${options.content_ItemHoverBackgroundColor || '#f7f7f7'};
            color: ${options.content_ItemHoverFontColor || '#333'};
          }
          ${mobileSafariMediaQuery} {
            #${languageSelectorId} .${styles.item}:hover {
              -webkit-tap-highlight-color: ${options.content_ItemHoverFontColor || '#333'};
            }
          }
        `}</style>
      </Helmet>
      <Dropdown
        id={languageSelectorId}
        className={styles.dropdown}
        onDropDownShow={loadAlternateUrls}
        hideCaretDown={!options.content_ShowExpandIcon}
        header={<Header selectedLanguage={selectedLanguage} presentation={options.toggle_Presentation} className={toggleFontSizeClassName} />}
      >
        {!isDesignerMode && (
          alternateUrls && alternateUrls.length
            ? (
              <Body
                selectedLanguage={selectedLanguage}
                languages={sortedLanguages}
                alternateUrls={alternateUrls}
                showFlags={options.content_ShowFlags}
                className={dropdownItemFontSizeClassName}
              />
            )
            : (
              <div className={styles.placeholder}>
                <Placeholder lineCount={sortedLanguages.length} className={styles.placeholderItem} />
              </div>
            )
        )}
      </Dropdown>
    </>
  );
};

DesktopLanguageSelectorBase.propTypes = {
  options: PropTypes.shape({
    toggle_Presentation: PropTypes.number.isRequired,
    toggle_FontFamily: PropTypes.string,
    toggle_FontSize: PropTypes.string.isRequired,
    toggle_AllCaps: PropTypes.bool.isRequired,
    toggle_FontColor: PropTypes.string,
    content_ShowFlags: PropTypes.bool.isRequired,
    content_AllCaps: PropTypes.bool.isRequired,
    content_ShowExpandIcon: PropTypes.bool.isRequired,
    content_BackgroundColor: PropTypes.string,
    content_ItemHoverBackgroundColor: PropTypes.string,
    content_BorderColor: PropTypes.string,
    content_ItemFontSize: PropTypes.string.isRequired,
    content_ItemFontColor: PropTypes.string,
    content_ItemHoverFontColor: PropTypes.string,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
  elementId: PropTypes.string.isRequired,
};

export default memo(DesktopLanguageSelectorBase);
