import { merge } from 'lodash';

const minLengthSegment = {
  desktop: null,
  mobile: null,
  tablet: null,
};

const borderSegment = {
  color: null,
  radius: null,
  style: 'none',
  width: null,
};

const defaultRow = {
  attributes: {
    className: null,
    id: null,
  },
  background: {
    color: null,
    desktopImage: null,
    fullWidth: false,
    hideImageOnMobile: false,
    imageAltText: null,
    mobileImage: null,
    video: null,
  },
  border: borderSegment,
  fullWidth: false,
  heroEffect: null,
  minHeight: minLengthSegment,
  rowAnimation: 'NONE',
  spacing: {
    hideSpaces: false,
    margin: '0px 0px 30px',
    padding: '12px 0px',
  },
  verticalAlignment: 'TOP',
};

const defaultColumn = {
  attributes: {
    className: null,
    id: null,
  },
  background: {
    color: null,
    desktopImage: null,
    hideImageOnMobile: false,
    imageAltText: null,
    mobileImage: null,
    video: null,
  },
  border: borderSegment,
  colspan: {
    lg: 12,
    md: 12,
    sm: 12,
  },
  columnAnimation: 'NONE',
  contentOrientation: 'VERTICAL',
  horizontalAlignment: 'JUSTIFY',
  minHeight: minLengthSegment,
  padding: null,
  verticalAlignment: 'INHERITED',
};

const defaultContentBlock = {
  model: {},
  horizontalAlignment: 'LEFT',
  minHeight: minLengthSegment,
  minWidth: minLengthSegment,
  packId: null,
  spacing: {
    margin: null,
    padding: null,
  },
  stretchHeight: false,
  stretchWidth: false,
};

export default function extendDefaultSettings(content) {
  return content.map(row => {
    row.columns = row.columns.map(column => {
      column.contentBlocks = column.contentBlocks.map(contentBlock => merge({}, defaultContentBlock, contentBlock));
      return merge({}, defaultColumn, column);
    });
    return merge({}, defaultRow, row);
  });
}
