import type { DocumentType } from 'behavior/documents';
import { DocumentsCollection, MultiTypeDocumentsCollection } from './types';

type Options = {
  page: {
    index: number;
    size: number;
  };
};

export const DOCUMENTS_REQUESTED = 'DOCUMENTS_REQUESTED' as const;
export const requestDocuments = (options: Options, documentType: DocumentType) => ({
  type: DOCUMENTS_REQUESTED,
  payload: { options, documentType },
});

export const DOCUMENTS_RECEIVED = 'DOCUMENTS_RECEIVED' as const;
export const documentsReceived = (documents: DocumentsCollection | null, page: number) => ({
  type: DOCUMENTS_RECEIVED,
  payload: { documents, page },
});

export const INVOICES_WITH_CREDITNOTES_REQUESTED = 'INVOICES_AND_CREDITNOTES_REQUESTED' as const;
export const requestInvoicesWithCreditNotes = (invoicesOptions: Options, creditNotesOptions: Options, documentType: DocumentType) => ({
  type: INVOICES_WITH_CREDITNOTES_REQUESTED,
  payload: { invoicesOptions, creditNotesOptions, documentType },
});

export const INVOICES_WITH_CREDITNOTES_RECEIVED = 'INVOICES_AND_CREDITNOTES_RECEIVED' as const;
export const invoicesWithCreditNotesReceived = (documents: MultiTypeDocumentsCollection | null, invoicesPage: number, creditNotesPage: number) => ({
  type: INVOICES_WITH_CREDITNOTES_RECEIVED,
  payload: { documents, invoicesPage, creditNotesPage },
});

export const MORE_INVOICES_OR_CREDITNOTES_REQUESTED = 'MORE_INVOICES_OR_CREDITNOTES_REQUESTED' as const;
export const requestMoreDocuments = (options: Options, documentType: DocumentType) => ({
  type: MORE_INVOICES_OR_CREDITNOTES_REQUESTED,
  payload: { options, documentType },
});

export const MORE_INVOICES_OR_CREDITNOTES_RECEIVED = 'MORE_INVOICES_OR_CREDITNOTES_RECEIVED' as const;
export const moreDocumentsReceived = (documents: MultiTypeDocumentsCollection | null, documentType: DocumentType, page: number) => ({
  type: MORE_INVOICES_OR_CREDITNOTES_RECEIVED,
  payload: { documents, documentType, page },
});

export type DocumentsReceivedAction = ReturnType<typeof documentsReceived>;
export type DocumentsAction = ReturnType<typeof requestDocuments> | DocumentsReceivedAction;

export type InvoicesAndCreditNotesReceivedAction = ReturnType<typeof invoicesWithCreditNotesReceived>;
export type InvoicesAndCreditNotesAction = ReturnType<typeof requestInvoicesWithCreditNotes> | InvoicesAndCreditNotesReceivedAction;

export type InvoicesOrCreditNotesReceivedAction = ReturnType<typeof moreDocumentsReceived>;
export type InvoicesOrCreditNotesAction = ReturnType<typeof requestMoreDocuments> | InvoicesOrCreditNotesReceivedAction;