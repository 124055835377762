import {
  PRODUCT_CALCULATED_FIELDS_LOADED,
  REVIEWS_RECEIVED,
  REVIEW_PROCESSED,
  SALES_AGREEMENT_PRODUCT_VARIANT_CHANGED,
  SALES_AGREEMENT_RECEIVED,
  VOLUME_PRICES_RECEIVED,
} from './actions';
import { VIEWER_CHANGED } from 'behavior/events';
import { createReducer } from 'utils/redux';
import { deleteProductCalculatedInfo } from 'behavior/products/product';
import { Presets } from 'behavior/pages/product';

export default createReducer(null, {
  [PRODUCT_CALCULATED_FIELDS_LOADED]: onProductLoaded,
  [REVIEWS_RECEIVED]: onReviewsReceived,
  [REVIEW_PROCESSED]: onReviewProcessed,
  [VOLUME_PRICES_RECEIVED]: onVolumePricesReceived,
  [VIEWER_CHANGED]: onViewerChanged,
  [SALES_AGREEMENT_RECEIVED]: onSalesAgreementReceived,
  [SALES_AGREEMENT_PRODUCT_VARIANT_CHANGED]: onSalesAgreementVariantChanged,
});

function onProductLoaded(state, action) {
  const stateVariants = state.product && state.product.variants;
  const { variants } = action.payload;

  if (variants) {
    for (const variant of variants) {
      const stateVariant = stateVariants.find(v => v.id === variant.id);
      if (stateVariant)
        variant.bomComponents = stateVariant.bomComponents;
    }
  }

  return {
    ...state,
    product: {
      ...state.product,
      ...action.payload,
      loaded: true,
    },
  };
}

function onReviewsReceived(state, action) {
  return {
    ...state,
    product: {
      ...state.product,
      reviews: {
        total: state.product.reviews.total,
        avg: state.product.reviews.avg,
        list: state.product.reviews.list.concat(action.payload),
      },
    },
  };
}

function onReviewProcessed(state, action) {
  return {
    ...state,
    product: {
      ...state.product,
      reviews: {
        ...state.product.reviews,
        saved: action.payload ? Date.now() : null,
      },
    },
  };
}

function onVolumePricesReceived(state, action) {
  return {
    ...state,
    volumePrices: action.payload,
  };
}

function onViewerChanged(state, _action) {
  if (!state.product)
    return state;

  const product = deleteProductCalculatedInfo(state.product);
  return { ...state, product };
}

function onSalesAgreementReceived(state, { payload }) {
  const {
    agreement,
    linesAvailability,
    canViewUom,
    allowUomSelection,
    productUom,
    productUoms,
  } = payload;

  if (!agreement)
    return state;

  const variantId = state.salesAgreement?.variantId;
  let linesToDisplay = buildAgreementLinesToDisplay(agreement.lines, linesAvailability, state.preset, variantId);
  linesToDisplay = adjustAgreementLinesToProduct(linesToDisplay, canViewUom, allowUomSelection, productUom, productUoms);

  return {
    ...state,
    salesAgreement: {
      ...agreement,
      variantId,
      linesToDisplay,
      linesAvailability,
      preSelectedLine: linesToDisplay && getAgreementLineById(linesToDisplay, state.salesAgreement?.preSelectedLine?.id),
    },
  };
}

function onSalesAgreementVariantChanged(state, { payload: { variantId, canViewUom, allowUOMSelection } }) {
  const agreement = state.salesAgreement || {
    lines: [],
    linesAvailability: [],
  };

  let linesToDisplay = buildAgreementLinesToDisplay(agreement.lines, agreement.linesAvailability, state.preset, variantId);

  if (linesToDisplay.length > 0) {
    const productUom = state.product?.uom;
    const productUoms = state.product?.uoms;
    linesToDisplay = adjustAgreementLinesToProduct(linesToDisplay, canViewUom, allowUOMSelection, productUom, productUoms);
  }

  return {
    ...state,
    salesAgreement: {
      ...agreement,
      variantId,
      linesToDisplay,
    },
  };
}

function buildAgreementLinesToDisplay(lines, linesAvailability, preset, variantId = null) {
  if (!linesAvailability)
    return [];

  const filterByVariant = preset !== Presets.DetailsWithMatrix;

  return lines.filter(line => linesAvailability.some(availability =>
    availability.lineId === line.id
    && (!filterByVariant || (!availability.variantId || areEqualOrEmpty(variantId, availability.variantId)))
    && (!line.isMaxEnforced || line.quantities.remaining > 0 || line.amounts.remaining > 0),
  ));
}

function areEqualOrEmpty(value1, value2) {
  return ((!value1 && !value2) || value1 === value2);
}

function adjustAgreementLinesToProduct(lines, canViewUom, allowUOMSelection, productUom, productUoms) {
  if (!allowUOMSelection || !canViewUom) {
    // If user cannot view UOMs or UOM selection is not allowed,
    // then show lines which either have no UOMs at all or match product's default UOM.
    const productUomId = productUom?.id.toUpperCase();
    return lines.filter(line => !line.uom || line.uom?.id.toUpperCase() === productUomId);
  }

  if (productUoms) {
    // If product has UOMs, then show lines which either have no UOMs at all or have a matching UOM specified.
    return lines.filter(line => {
      if (!line.uom)
        return true;

      const lineUom = line.uom?.id.toUpperCase();
      return productUoms.some(uom => uom.id.toUpperCase() === lineUom);
    });
  }

  return null;
}

function getAgreementLineById(lines, lineId) {
  return lines.find(line => line.id === lineId);
}
