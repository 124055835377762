import { of } from 'rxjs';
import { visualDesignerLoaded } from 'behavior/visualDesigner/actions';
import { insiteEditorLoaded } from 'behavior/insiteEditor/actions';
import { routesBuilder } from 'routes';
import { rewriteTo } from 'behavior/routing';
import { getVisualDesignerMode, isInsiteEditorLocation } from './helpers';

export default (_, state$) => {
  const state = state$.value;
  const isInsiteEditor = isInsiteEditorLocation(state.routing.navigatingTo.location);
  const isInsiteEditorInitialized = state.insiteEditor.initialized;

  if (isInsiteEditor || isInsiteEditorInitialized) {
    const rewriteToHome = rewriteTo({
      ...routesBuilder.forHome(),
      params: { language: state.localization.currentLanguage.id },
    });

    return of({
      action$: isInsiteEditorInitialized
        ? of(rewriteToHome)
        : of(insiteEditorLoaded(), rewriteToHome),
    });
  }

  const mode = getVisualDesignerMode(state.routing.navigatingTo.location);
  return of({
    action$: of(visualDesignerLoaded(mode)),
    page: { ...state.page },
  });
};
