exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Tooltip_tooltip{text-align:inherit}.Tooltip_tooltip:focus{outline:1px dotted var(--text_Color,grey)}.Tooltip_popup{display:inline-block;position:absolute;visibility:hidden;opacity:0;width:270px;max-width:320px;border:2px solid var(--popup_BorderColor,#e6e6e6);background:var(--popup_BackgroundColor,#fff);box-shadow:0 0 5px rgba(0,0,0,.15);padding:10px;line-height:1.4em;text-align:left;transition:visibility .3s,opacity .3s;overflow-y:auto;z-index:9400000;will-change:transform}.Tooltip_popup h4{text-transform:uppercase;margin:0;padding:0}.Tooltip_popup.Tooltip_opened{visibility:visible;opacity:1}.Tooltip_sign{padding:.2em;color:var(--link_Color,#1f7bc9);cursor:pointer}", ""]);

// exports
exports.locals = {
	"tooltip": "Tooltip_tooltip",
	"popup": "Tooltip_popup",
	"opened": "Tooltip_opened",
	"sign": "Tooltip_sign"
};