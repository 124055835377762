import PropTypes from 'prop-types';
import DesktopAccountMenuBase from './DesktopAccountMenuBase';

const DesktopAccountMenuBlock = ({ model, isDesignerMode, id }) => (
  <DesktopAccountMenuBase options={model} isDesignerMode={isDesignerMode} elementId={id} />
);

DesktopAccountMenuBlock.propTypes = {
  model: PropTypes.object.isRequired,
  isDesignerMode: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

export default DesktopAccountMenuBlock;
