import type { Filter, MultiTypeDocumentsFilter, InvoicesAndCreditNotesResponse, MultiTypeDocumentsCollection } from './types';
import type { Handler } from '../types';
import type { BackTo } from 'routes/types';
import { RouteName } from 'routes';
import { initPageContent, initSystemPageContent, SystemPage, SystemPageData } from '../system';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { requestInvoicesWithCreditNotes } from './actions';
import { PageComponentNames } from '../componentNames';
import DateOnly from 'date-only';
import { loadSystemPageQuery } from '../system/queries';
import { getDocumentField, DocumentType } from 'behavior/documents';

export const size = 10;

export default function (documentType: DocumentType, componentName: PageComponentNames, query: string) {
  const handler: Handler<DocumentsRouteData, InvoicesPage | NotFoundPage> = ({ params, options: pageOptions }, state$, { api }) => {
    const filter = normalizeFilter(params && params.filter, documentType);
    if (params?.previewToken) {
      const documentsFieldName = getDocumentField(documentType);

      return api.graphApi<DocumentsPreviewPageResponse>(loadSystemPageQuery(documentsFieldName)).pipe(
        map(({ pages }) => {
          const page = pages[documentsFieldName];
          if (!page)
            return null;

          return {
            page: {
              component: componentName,
              docs: {
                invoices: {
                  list: {
                    totalCount: 0, items: [],
                  },
                },
                creditNotes: {
                  list: {
                    totalCount: 0, items: [],
                  },
                },
              },
              size,
              filter,
              ...page,
            },
          };
        }),
        initSystemPageContent(),
      );
    }

    const onlyItems = pageOptions && pageOptions.onlyItems;
    const backTo = pageOptions && pageOptions.backTo;
    const { invoicesOptions, creditNotesOptions } = createMultiTypeOptions(params, filter, onlyItems);

    if (onlyItems)
      return of({
        action$: of(requestInvoicesWithCreditNotes(invoicesOptions, creditNotesOptions, documentType)),
        page: {
          ...state$.value.page as InvoicesPage,
          filter,
        },
      });

    return api.graphApi<DocumentsPageResponse>(query, { invoicesOptions, creditNotesOptions }).pipe(
      map(({ pages: { page }, documents: pageDocuments }) => {
        if (!page)
          return null;

        const initializedPage = initPageContent(page);
        const documents = pageDocuments;
        if (!documents) {
          return {
            page: {
              component: PageComponentNames.NotFound,
              ...initializedPage,
            } as NotFoundPage,
          };
        }

        const resultPage = {
          component: componentName,
          docs: documents,
          size,
          filter,
          backTo,
          ...initializedPage,
        } as InvoicesPage;

        return { page: resultPage };
      }),
    );
  };

  return handler;
}

export function createGeneralOptions(documentType: DocumentType, params?: Params, filter?: Filter, onlyItems?: boolean) {
  const index = (params && params.index) || 0;
  const page = onlyItems
    ? { index, size }
    : { index: 0, size: size + size * index };

  const useCurrentFilter = documentType === params?.filter.documentTypeToUpdate || params?.filter.documentTypeToUpdate === '';
  const resultFilter = useCurrentFilter ? prepareFilter(filter) : { orderDate: { from: null } };

  if(documentType === DocumentType.CreditNote)
    return { ...resultFilter, page, positiveOutstandingAmountOnly: true };

  return { ...resultFilter, page };
}

export function createMultiTypeOptions(params?: Params, filter?: Filter, onlyItems?: boolean) {
  const index = (params && params.index) || 0;

  const invoicesPage = onlyItems
    ? { index, size }
    : { index: 0, size: size + size * index };

  const creditNotesPage = onlyItems
    ? { index, size }
    : { index: 0, size: size + size * index };

  const resultFilter = prepareFilter(filter);

  switch (params?.filter && params.filter.documentTypeToUpdate) {
    case DocumentType.Invoice: {
      return {
        invoicesOptions: { ...resultFilter, page: invoicesPage },
        creditNotesOptions: { orderDate: { from: null }, page: creditNotesPage, positiveOutstandingAmountOnly: true },
      };
    }
    case DocumentType.CreditNote: {
      return {
        invoicesOptions: { orderDate: { from: null }, page: invoicesPage },
        creditNotesOptions: { ...resultFilter, page: creditNotesPage, positiveOutstandingAmountOnly: true },
      };
    }
    default: {
      return {
        invoicesOptions: { ...resultFilter, page: invoicesPage },
        creditNotesOptions: { ...resultFilter, page: creditNotesPage, positiveOutstandingAmountOnly: true },
      };
    }
  }
}

function prepareFilter(filter: any) {
  return {
    orderDate: filter?.orderDate,
    orderId: filter?.orderId,
    documentId: filter?.documentId,
  };
}

export function normalizeFilter(filter?: Filter, documentType?: DocumentType) {
  if (filter && filter.orderDate)
    return filter;

  if (documentType && documentType === DocumentType.Invoice)
    return { ...filter, orderDate: { from: null } };

  const from = new Date();
  let fromDate = from.getDate();
  const fromMonth = from.getMonth() - 3;
  const fromMonthLastDay = getNumberOfDaysInMonth(fromMonth + 1, from.getFullYear());
  if (fromMonthLastDay < fromDate)
    fromDate = fromMonthLastDay;

  from.setMonth(fromMonth, fromDate);

  return { ...filter, orderDate: { from: DateOnly.toISOString(from) } };
}

function getNumberOfDaysInMonth(month: number, year: number) {
  return new Date(year, month, 0).getDate();
}

type Params = {
  filter: MultiTypeDocumentsFilter;
  index?: number;
  previewToken?: string;
};

type DocumentsRouteData = {
  routeName:
  | RouteName.Orders
  | RouteName.Quotes
  | RouteName.Invoices
  | RouteName.ReturnOrders
  | RouteName.CreditNotes
  | RouteName.ReturnReceipts
  | RouteName.Shipments;
  params?: Params;
  options?: {
    onlyItems?: boolean;
    backTo?: BackTo;
  };
};

type NotFoundPage = {
  component: PageComponentNames.NotFound;
};

type DocumentsPreviewPageResponse = {
  pages: {
    [documents in ReturnType<typeof getDocumentField>]: SystemPageData;
  };
};

type DocumentsPageResponse = {
  pages: {
    page: SystemPageData;
  };
} & InvoicesAndCreditNotesResponse;

type InvoicesPage = SystemPage & {
  component: PageComponentNames;
  docs: MultiTypeDocumentsCollection;
  size: number;
  filter: Filter;
  backTo?: BackTo;
};