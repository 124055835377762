import { MyAccountAction, ORDERING_EXPERIENCE_LOADED } from './actions';
import { OrderingExperience } from './types';

type State = {
  orderingExperience: OrderingExperience;
};

export default function (state: State, action: MyAccountAction) {
  switch (action.type) {
    case ORDERING_EXPERIENCE_LOADED:
      return { ...state, orderingExperience: action.payload };
    default:
      return state;
  }
}
