export const AGREEMENTS_REQUESTED = 'AGREEMENTS_REQUESTED';
export const requestAgreements = (activeOnly, index = 0, size = 10) => ({
  type: AGREEMENTS_REQUESTED,
  payload: { activeOnly, index, size },
});
export const requestAgreement = id => ({
  type: AGREEMENTS_REQUESTED,
  payload: { id },
});

export const AGREEMENTS_RECEIVED = 'AGREEMENTS_RECEIVED';
export const notifyAgreementsReceived = (agreements, append = false) => ({
  type: AGREEMENTS_RECEIVED,
  payload: { agreements, append },
});

export const AGREEMENTS_SEARCH_PRODUCT_IDS = 'AGREEMENTS_SEARCH_PRODUCT_IDS';
export const searchForProductIds = keywords => ({
  type: AGREEMENTS_SEARCH_PRODUCT_IDS,
  payload: { keywords },
});

export const AGREEMENTS_SEARCH_PRODUCT_IDS_RECEIVED = 'AGREEMENTS_SEARCH_PRODUCT_IDS_RECEIVED';
export const productIdsReceived = (keywords, ids) => ({
  type: AGREEMENTS_SEARCH_PRODUCT_IDS_RECEIVED,
  payload: { keywords, ids },
});

export const AGREEMENT_LINES_AVAILABILITY_REQUESTED = 'AGREEMENT_LINES_AVAILABILITY_REQUESTED';
export const requestAgreementLinesAvailability = agreement => ({
  type: AGREEMENT_LINES_AVAILABILITY_REQUESTED,
  payload: agreement,
});

export const AGREEMENT_LINES_AVAILABILITY_RECEIVED = 'AGREEMENT_LINES_AVAILABILITY_RECEIVED';
export const receiveAgreementLinesAvailability = availableLines => ({
  type: AGREEMENT_LINES_AVAILABILITY_RECEIVED,
  payload: availableLines,
});