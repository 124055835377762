import { createReducer } from 'utils/redux';
import { AGREEMENTS_RECEIVED, AGREEMENTS_SEARCH_PRODUCT_IDS_RECEIVED, AGREEMENT_LINES_AVAILABILITY_RECEIVED } from './actions';

export default createReducer(null, {
  [AGREEMENTS_RECEIVED]: onAgreementsReceived,
  [AGREEMENTS_SEARCH_PRODUCT_IDS_RECEIVED]: onSearchProductIdsReceived,
  [AGREEMENT_LINES_AVAILABILITY_RECEIVED]: onAgreementLinesAvailabilityReceived,
});

function onAgreementsReceived(state, { payload }) {
  const newAgreements = payload.agreements || [];
  const items = payload.append && state.agreements
    ? state.agreements.items.concat(newAgreements)
    : newAgreements;
  const agreements = {
    items,
    loadedLength: newAgreements.length,
  };
  return { ...state, agreements };
}

function onSearchProductIdsReceived(state, { payload: search }) {
  return { ...state, search };
}

function onAgreementLinesAvailabilityReceived(state, { payload: availableLines }) {
  if (availableLines.length === 0)
    return state;

  const lines = state.agreement.lines.map(line => {
    const availableLine = availableLines.find(({ lineId }) => lineId === line.id);

    if (!(availableLine?.variantId && line.product && line.product.variants)
      && !line.product?.variants?.some(variant => !variant.isOrderable)
    ) {
      return {
        ...line,
        isLineOrderable: !!availableLine,
      };
    }

    const variantComponentGroups = [];

    line.product.variantComponentGroups.forEach(componentGroup => {
      const components = [];

      componentGroup.components.forEach(component => {
        const variants = component.variants.filter(componentVariantId => {
          const isVariantOrderable = line.product.variants.find(variant => variant.id === componentVariantId).isOrderable;
          return isVariantOrderable && availableLines.some(
            ({ lineId, variantId }) => lineId === line.id && (!variantId || variantId === componentVariantId),
          );
        });

        if (!variants.length)
          return;

        components.push({
          ...component,
          variants,
        });
      });

      if (!components.length)
        return;

      variantComponentGroups.push({
        ...componentGroup,
        components,
      });
    });

    if (!variantComponentGroups.length) {
      return {
        ...line,
        isLineOrderable: false,
      };
    }

    return {
      ...line,
      isLineOrderable: !!availableLine,
      product: {
        ...line.product,
        variantComponentGroups,
      },
    };
  });

  return {
    ...state,
    agreement: {
      ...(state.agreement),
      lines,
    },
  };
}
