import { ofType } from 'redux-observable';
import { map, tap, pluck } from 'rxjs/operators';
import { VISUAL_DESIGNER_LANGUAGE_CHANGED } from './actions';
import { urlLanguageChanged } from 'behavior/localization/actions';

function changeLanguage(action$, _, { api }) {
  return action$.pipe(
    ofType(VISUAL_DESIGNER_LANGUAGE_CHANGED),
    pluck('payload'),
    tap(languageId => api.setLanguage(languageId, false)),
    map(urlLanguageChanged),
  );
}

export default changeLanguage;