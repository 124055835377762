import { redirectTo } from '../actions';
import { RouteName } from 'routes';

const ignoreRoutes = new Set([
  RouteName.Home,
  RouteName.NotFound,
  RouteName.WebsiteRedirect,
]);

const trailingRoutes = new Set([
  RouteName.ProductList,
  RouteName.MyAccount,
  RouteName.SubAccounts,
  RouteName.Orders,
  RouteName.Quotes,
  RouteName.Invoices,
  RouteName.ReturnOrders,
  RouteName.CreditNotes,
  RouteName.ReturnReceipts,
  RouteName.Shipments,
  RouteName.SalesAgreements,
]);

export function trailing(ignoreRoutes, trailingRoutes, route, location) {
  if (ignoreRoutes.has(route.routeName))
    return;

  const trailingRoute = trailingRoutes.has(route.routeName);
  const trailingPath = location.pathname.endsWith('/');

  if (trailingRoute && trailingPath)
    return;

  if (!trailingRoute && !trailingPath)
    return;

  const pathname = trailingRoute
    ? location.pathname + '/'
    : location.pathname
      .substring(0, location.pathname.length - 1);

  return redirectTo(pathname + location.search, 301, route);
}

export default trailing.bind(null, ignoreRoutes, trailingRoutes);
