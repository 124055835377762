import createEligibleQuantity, { ProductWithUom } from './eligibleQuantity';
import { createUrl } from '../url';
import { ProductTrackingOptions, ProductGeneralInfo } from './types';

export type CalculatedInfo = {
  inventory: number | null;
  isOrderable: boolean | undefined;
  price: number | null;
};

export interface ProductWithCalculatedInfo extends ProductGeneralInfo, ProductWithUom {
  calculatedInfo?: CalculatedInfo | null;
  stockLevels?: null | {
    outOfStock: number;
  };
}

export default function (product: ProductWithCalculatedInfo, options: ProductTrackingOptions) {
  const { currencyId, origin } = options;
  const { url, calculatedInfo, stockLevels } = product;

  if (!calculatedInfo || calculatedInfo.price == null)
    return null;

  const { inventory, isOrderable } = calculatedInfo;

  let stock: string;
  if ((inventory == null && isOrderable) || (stockLevels && (inventory || 0) > stockLevels.outOfStock))
    stock = 'InStock';
  else
    stock = 'OutOfStock';

  return {
    offers: {
      '@type': 'Offer',
      url: createUrl(url, origin),
      price: calculatedInfo.price.toFixed(2),
      priceCurrency: currencyId,
      availability: `https://schema.org/${stock}`,
      ...createEligibleQuantity(product, options),
    },
  };
}
