import type { Cookies } from '../cookies';
import type { ToolsStorage } from '../tools';
import type { GraphApiResponse } from './types';
import { tap, pluck, share, retryWhen } from 'rxjs/operators';
import { identity, Observable, Observer } from 'rxjs';
import { createAuthTokenStorage } from './authentication';
import { createRequestTracker } from './tracking';
import { createLocalizationStorage } from './localization';
import { createConnectionTracker } from './connectionTracker';
import retryRequestsPolicy from './retryRequests';
import { createErrorCodesTracker } from './errorCodesTracker';
import { createExtensionsTracker } from './extensionsTracker';
import { logger } from '../logs';

type Fetch = (input: RequestInfo, init?: RequestInit) => Promise<Response>;

type ApiParams = {
  path: string;
  headers?: Record<string, string>;
  fetch: Fetch;
  fetchAgent: unknown;
  multiTab?: boolean;
  token?: string;
  protocol: string;
  cookies: Cookies;
  toolsStorage: ToolsStorage;
};

type GraphApiOptions = {
  authToken?: string | null;
  retries?: number;
  useCookies?: boolean;
  files?: FileList;
  useStableApi?: boolean;
  ignoreStatuses?: boolean;
};

export type Api = ReturnType<typeof createApi>;

export function createApi(options: ApiParams) {
  const requestTracker = createRequestTracker();
  const customHeaders: Record<string, string> = { ...options.headers };
  const graphApiPath = options.path;
  const stableGraphApiPath = graphApiPath + '/stable';

  const fetch = makeReactiveFetch(options.fetch, options.fetchAgent);

  const tokenStorage = createAuthTokenStorage({
    broadcast: options.multiTab || false,
    initialToken: options.token,
  });

  const localizationStorage = createLocalizationStorage({
    cookies: options.cookies,
    maxAge: 31536000, // 365 days measured in seconds.
  });

  const toolsStorage = options.toolsStorage;

  const { connection$, trackConnection } = createConnectionTracker(options.fetch);
  const { errors$, trackErrors } = createErrorCodesTracker([401, 503]);
  const { extensions$, trackExtensions } = createExtensionsTracker();
  const emptyOptions = {};

  function graph<T = any>(
    query: string,
    variables?: unknown,
    options: GraphApiOptions = emptyOptions,
  ): Observable<T> {

    const authToken = 'authToken' in options ? options.authToken : tokenStorage.getValue();
    const languageId = localizationStorage.getValue();
    const toolsEnabled = toolsStorage.anyToolEnabled();
    const { files = null, retries = 2, useCookies = false, ignoreStatuses } = options;

    const credentials = useCookies || !authToken || toolsEnabled ? 'include' : 'omit';
    const headers: Record<string, string> = { ...customHeaders };

    addAuthHeader(headers, authToken);
    if (languageId)
      headers['X-LanguageId'] = languageId;

    let body;

    if (files) {
      body = new FormData();
      body.append('query', query);
      body.append('variables', JSON.stringify(variables));
      for (const file of files)
        body.append('files[]', file, file.name);
    } else {
      headers['Content-Type'] = 'application/json; charset=UTF-8';
      body = JSON.stringify({ query, variables });
    }

    const path = options.useStableApi ? stableGraphApiPath : graphApiPath;
    return fetch<GraphApiResponse>(path, {
      headers,
      credentials,
      method: 'POST',
      body,
    }).pipe(
      share(),
      retryWhen(retryRequestsPolicy(retries)),
      trackConnection,
      tap(logGraphQLErrors),
      trackExtensions,
      pluck('data'),
      requestTracker.trackObservable,
      ignoreStatuses ? identity : trackErrors,
    );
  }

  return {
    setLanguage: localizationStorage.saveValue,
    setAuthToken: (token: string | null, broadcast = true) => tokenStorage.saveValue(token, broadcast),
    headers: {
      add: (name: string, value: string) => customHeaders[name] = value,
      delete: (name: string) => void (delete customHeaders[name]),
    },
    authChanges$: tokenStorage.new$,
    connection$,
    errors$,
    extensions$,
    graphApi: graph,
    fetch<T = any>(url: string, options?: RequestInit) {
      return fetch<T>(url, {
        method: 'GET',
        ...options,
      }).pipe(
        requestTracker.trackObservable,
      );
    },
    isRunning() {
      return requestTracker.hasInProgress();
    },
    isReady$: requestTracker.isReady$,
    trackObservable<T>(observable: Observable<T>) {
      return requestTracker.trackObservable(observable);
    },
  };
}

function makeReactiveFetch(fetch: Fetch, agent: unknown) {

  return <T>(url: string, options?: RequestInit): Observable<T> => {
    return new Observable<T>((observer: Observer<T>) => {
      const abortController = typeof AbortController === 'function' ? new AbortController() : null;
      const fetchOptions = {
        ...options,
        signal: abortController && abortController.signal,
        agent,
      };

      const handleError = (e: any) => {
        if (e.name === 'AbortError')
          observer.complete();
        else
          observer.error(e);
      };
      fetch(url, fetchOptions)
        .then(parseFetchResponse, handleError)
        .then(r => {
          observer.next(r);
          observer.complete();
        }, handleError);
      return () => {
        abortController && abortController.abort();
      };
    });
  };
}

function parseFetchResponse(response: Response) {
  if (!response.ok)
    return response.text().then(r => Promise.reject({ status: response.status, response: tryParseJson(r) }));

  const contentType = response.headers.get('Content-Type') || '';
  if (contentType.startsWith('application/json'))
    return response.json();
  else
    return response.text();
}

function addAuthHeader(headers: Record<string, string>, authToken: string | null | undefined) {
  if (authToken === undefined)
    headers['X-UseAuthCookie'] = 'true';
  else if (authToken)
    headers.Authorization = 'Bearer ' + authToken;
}

function logGraphQLErrors(response: GraphApiResponse) {
  if (response.errors)
    response.errors.forEach(e => logger.warn(e.message));
}

function tryParseJson(text: string) {
  try {
    return text && text.length ? JSON.parse(text) : text;
  }
  catch {
    return text;
  }
}
