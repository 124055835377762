import { InvoicesOrCreditNotesAction, InvoicesOrCreditNotesReceivedAction, MORE_INVOICES_OR_CREDITNOTES_RECEIVED } from '../actions';
import { createReducer } from 'utils/redux';
import { MultiTypeDocumentsCollection } from '../types';
import { DocumentType } from 'behavior/documents';

type State = {
  docs: MultiTypeDocumentsCollection | null;
};

export default createReducer<State, InvoicesOrCreditNotesAction>(null as any, {
  [MORE_INVOICES_OR_CREDITNOTES_RECEIVED]: onDocumentsReceived,
});

function onDocumentsReceived(state: State, action: InvoicesOrCreditNotesReceivedAction) {
  const {
    documents,
    documentType,
    page,
  } = action.payload;

  if (!state.docs || page === 0 || !documents)
    return {
      ...state,
      docs: documents,
    };

  const { invoices, creditNotes } = documents;
  if (documentType === DocumentType.Invoice && invoices && invoices.list.items) {
    return {
      ...state,
      docs: {
        invoices: {
          list: {
            items: state.docs.invoices?.list.items?.concat(invoices.list.items),
            totalCount: invoices.list.totalCount,
          },
        },
        creditNotes: {
          list: {
            items: state.docs.creditNotes?.list.items,
            totalCount: state.docs.creditNotes?.list.totalCount,
          },
        },
      },
    };
  }
  else if (documentType === DocumentType.CreditNote && creditNotes && creditNotes.list.items) {
    return {
      ...state,
      docs: {
        invoices: {
          list: {
            items: state.docs.invoices?.list.items,
            totalCount: state.docs.invoices?.list.totalCount,
          },
        },
        creditNotes: {
          list: {
            items: state.docs.creditNotes?.list.items?.concat(creditNotes.list.items),
            totalCount: creditNotes.list.totalCount,
          },
        },
      },
    };
  }

  return {
    ...state,
    docs: documents,
  };
}
