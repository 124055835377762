import type { Product } from './types';

export const PRODUCT_COMPARISON_ADD_PRODUCT = 'PRODUCT_COMPARISON_ADD_PRODUCT' as const;
export const addProductToComparison = ({ id, title, image, url }: Product) => ({
  type: PRODUCT_COMPARISON_ADD_PRODUCT,
  payload: { id, title, image, url },
});

export const PRODUCT_COMPARISON_REMOVE_PRODUCT = 'PRODUCT_COMPARISON_REMOVE_PRODUCT' as const;
export const removeProductFromComparison = (id: string) => ({
  type: PRODUCT_COMPARISON_REMOVE_PRODUCT,
  payload: { id },
});

export const PRODUCT_COMPARISON_REMOVE_ALL_PRODUCTS = 'PRODUCT_COMPARISON_REMOVE_ALL_PRODUCTS' as const;
export const removeAllProductsFromComparison = () => ({
  type: PRODUCT_COMPARISON_REMOVE_ALL_PRODUCTS,
});

export const PRODUCT_COMPARISON_REQUEST_INITIALIZATION = 'PRODUCT_COMPARISON_REQUEST_INITIALIZATION' as const;
export const requestProductComparisonInitialization = () => ({
  type: PRODUCT_COMPARISON_REQUEST_INITIALIZATION,
});

export const PRODUCT_COMPARISON_INITIALIZE = 'PRODUCT_COMPARISON_INITIALIZE' as const;
export const initializeProductComparison = (productsToCompare: Product[]) => ({
  type: PRODUCT_COMPARISON_INITIALIZE,
  payload: { productsToCompare },
});

export type AddProductToComparisonAction = ReturnType<typeof addProductToComparison>;
export type RemoveProductFromComparisonAction = ReturnType<typeof removeProductFromComparison>;
type RemoveAllProductsFromComparisonAction = ReturnType<typeof removeAllProductsFromComparison>;
type RequestProductComparisonInitializationAction = ReturnType<typeof requestProductComparisonInitialization>;
export type InitializeProductComparisonAction = ReturnType<typeof initializeProductComparison>;

export type ProductComparisonAction =
  | AddProductToComparisonAction
  | RemoveProductFromComparisonAction
  | RemoveAllProductsFromComparisonAction
  | RequestProductComparisonInitializationAction
  | InitializeProductComparisonAction;
