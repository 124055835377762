import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useContext } from 'react';
import { trackProductClick } from 'behavior/analytics';
import ProductTrackingContext from './ProductTrackingContext';

const WithProductClickTracking = ({ children }) => {
  const dispatch = useDispatch();
  const { product, trackingSource } = useContext(ProductTrackingContext);

  const onClickHandler = useCallback(() => {
    product && dispatch(trackProductClick({ product, source: trackingSource }));
  }, [product, trackingSource]);

  return children(onClickHandler);
};

WithProductClickTracking.propTypes = {
  children: PropTypes.func.isRequired,
};

export default WithProductClickTracking;