exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Video_wrapper{position:absolute;top:0;left:50%;width:100%;height:100%;display:-webkit-flex;display:flex;overflow:hidden;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;-webkit-transform:translate3d(-50%,0,0) scale(1.01);transform:translate3d(-50%,0,0) scale(1.01)}.Video_wrapper:after{content:\"\";display:block;position:absolute;top:0;left:0;width:100%;height:100%}.Video_frame{position:relative;width:100%;min-width:100%;height:0;-webkit-flex-shrink:0;flex-shrink:0;padding-bottom:56.25%}.Video_frame>iframe{display:block;position:absolute;top:0;left:0;width:100%;height:100%;border:0;margin:0;padding:0}.Video_buffering{opacity:0;transition:opacity .5s}.Video_playing{opacity:1}@media print,screen{html.print .Video_wrapper{display:none}}", ""]);

// exports
exports.locals = {
	"videoFrameAspectRatio": ".5625",
	"wrapper": "Video_wrapper",
	"frame": "Video_frame",
	"buffering": "Video_buffering",
	"playing": "Video_playing"
};