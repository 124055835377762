import { useEffect, useMemo } from 'react';
import { navigateTo } from 'behavior/events';
import { useDispatch } from 'react-redux';
import { isModifierKeyPressed } from 'utils/helpers';
import { logger } from 'utils/logs';
import { isAbsoluteUrl } from 'utils/url';
import { useServices } from 'utils/services';
import type { HTMLAnchorFactory, LinkClickHandler } from './types';
import type { RouteData } from 'routes';

type Props = {
  createLink: HTMLAnchorFactory;
  to?: RouteData;
  url?: string;
  onClick?: LinkClickHandler;
  omitScroll?: boolean;
  replaceHistory?: boolean;
  options?: RouteData['options'];
  disabled?: boolean;
};

const NavigationLink = ({
  createLink,
  to,
  url,
  onClick,
  omitScroll,
  replaceHistory,
  options,
  disabled,
}: Props) => {
  const dispatch = useDispatch();
  const { context: appContext } = useServices();

  const linkUrl = useMemo(() => {
    if (!url)
      return;

    return url.startsWith(appContext.origin)
      ? url.substring(appContext.origin.length)
      : url;
  }, [url]);

  useEffect(() => {
    if (!linkUrl)
      logBrokenLinksWarning();
  }, [linkUrl]);

  const onLinkClick = (event: React.MouseEvent<HTMLAnchorElement> & KeyboardEvent) => {
    if (event.button === 1 || isModifierKeyPressed(event))
      return;

    const { currentTarget: { target } } = event;
    if (!linkUrl || isAbsoluteUrl(linkUrl) || (target && target !== '_self'))
      return;

    event.preventDefault();
    if (disabled)
      return;

    const routeData = getRouteData(to, options);
    const ignore = (onClick && onClick(event, routeData)) === false;
    if (!ignore)
      dispatch(navigateTo(routeData, linkUrl, { omitScroll, replaceHistory }));
  };

  return createLink(linkUrl, onLinkClick);
};

export default NavigationLink;

function getRouteData(to: RouteData | undefined, options: RouteData['options']): RouteData | undefined {
  if (!to || !options)
    return to;

  return { ...to, options: { ...to.options, ...options } };
}

function logBrokenLinksWarning() {
  logger.warn('There are broken links on the website. Please use document.querySelectorAll("a[data-broken]") to find them.');
}
