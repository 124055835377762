import type { AdditionalCustomerData, ExtraPaymentData } from 'behavior/pages/checkout';
import type { InvoiceResult, PaymentInput } from './types';

type Invoice = {
  id: string;
  orderId: string;
  paymentAmount: number;
};

type CreditNote = {
  id: string;
  orderId: string;
};

export const SUBMIT_INVOICE_ORDER = 'INVOICE_PAYMENT/SUBMIT_INVOICE_ORDER' as const;
export const submitInvoiceOrder = (
  orderId: string,
  paymentInput: PaymentInput,
  additionalCustomerData?: AdditionalCustomerData,
  extraPaymentData?: ExtraPaymentData,
) => ({
  type: SUBMIT_INVOICE_ORDER,
  payload: { orderId, paymentInput, additionalCustomerData, extraPaymentData },
});

export const SUBMIT_INVOICE_ORDER_RESULT_RECEIVED = 'INVOICE_PAYMENT/SUBMIT_INVOICE_ORDER_RESULT_RECEIVED' as const;
export const submitInvoiceOrderResultReceived = (result: InvoiceResult) => ({
  type: SUBMIT_INVOICE_ORDER_RESULT_RECEIVED,
  payload: { result },
});

export const CREATE_INVOICE_ORDER = 'INVOICE_PAYMENT/CREATE_INVOICE_ORDER' as const;
export const createInvoiceOrder = (invoices: Invoice[], creditNotes: CreditNote[]) => ({
  type: CREATE_INVOICE_ORDER,
  payload: { invoices, creditNotes },
});

export const CREATE_INVOICE_ORDER_RESULT_RECEIVED = 'INVOICE_PAYMENT/CREATE_INVOICE_ORDER_RESULT_RECEIVED' as const;
export const createInvoiceOrderResultReceived = (result: InvoiceResult) => ({
  type: CREATE_INVOICE_ORDER_RESULT_RECEIVED,
  payload: { result },
});

export const PAYMENT_METHOD_EXTRA_DATA_REQUESTED = 'INVOICE_PAYMENT/PAYMENT_METHOD_EXTRA_DATA/REQUESTED' as const;
export const requestPaymentMethodExtraData = (orderId: string, paymentMethodId: string) => ({
  type: PAYMENT_METHOD_EXTRA_DATA_REQUESTED,
  payload: { orderId, paymentMethodId },
});

export const PAYMENT_METHOD_EXTRA_DATA_RECEIVED = 'INVOICE_PAYMENT/ADDITIONAL_CUSTOMER_DATA/RECEIVED' as const;
export const receivePaymentMethodExtraData = (
  paymentMethodId: string,
  additionalCustomerData: AdditionalCustomerData,
  extraPaymentData: ExtraPaymentData,
) => ({
  type: PAYMENT_METHOD_EXTRA_DATA_RECEIVED,
  payload: { paymentMethodId, additionalCustomerData, extraPaymentData },
});

export type CreateInvoiceOrderResultReceivedAction = ReturnType<typeof createInvoiceOrderResultReceived>;
export type SubmitInvoiceOrderResultReceivedAction = ReturnType<typeof submitInvoiceOrderResultReceived>;
export type ReceivePaymentMethodExtraData = ReturnType<typeof receivePaymentMethodExtraData>;

export type InvoicePaymentAction =
  | CreateInvoiceOrderResultReceivedAction
  | SubmitInvoiceOrderResultReceivedAction
  | ReceivePaymentMethodExtraData
  | ReturnType<
    | typeof submitInvoiceOrder
    | typeof createInvoiceOrder
    | typeof requestPaymentMethodExtraData
  >;
