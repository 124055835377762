import { NAVIGATION_REQUESTED } from './actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { unsetLoadingIndicator } from 'behavior/loadingIndicator';

const subscribers = [];
let pendingAction = null;

const NAVIGATING_CONTINUE_PENDING = 'NAVIGATING_CONTINUE_PENDING';
const continuePending = action => ({
  type: NAVIGATING_CONTINUE_PENDING,
  payload: action,
});

export const omitNavigationMiddleware = _store => next => action => {
  switch (action.type) {
    case NAVIGATION_REQUESTED:
      if (!subscribers.length)
        return next(action);

      const routeData = action.payload.routeData;
      for (const shouldPostponeNavigation of subscribers) {
        if (shouldPostponeNavigation(routeData)) {
          pendingAction = action;
          return;
        }
      }

      return next(action);
    case NAVIGATING_CONTINUE_PENDING:
      pendingAction = null;
      return next(action.payload);
    default:
      return next(action);
  }
};

export const useOmitNavigation = shouldPostponeNavigation => {
  useEffect(() => {
    subscribers.push(shouldPostponeNavigation);

    return () => {
      const index = subscribers.indexOf(shouldPostponeNavigation);
      subscribers.splice(index, 1);
    };
  }, [shouldPostponeNavigation]);

  const dispatch = useDispatch();
  const resume = () => {
    if (!pendingAction)
      return;

    dispatch(continuePending(pendingAction));
    pendingAction = null;
  };
  const discard = () => dispatch(unsetLoadingIndicator());

  return { resume, discard };
};