import styles from './Summary.module.scss';
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import SummaryBase from './SummaryBase';
import { mobileSafariMediaQuery } from 'utils/detections';
import { mousePointerClassName } from 'components/detection';

const DesktopBasketSummaryBlock = ({ id, model, isDesignerMode }) => {
  const options = useMemo(() => ({
    compact: false,
    showBasketLinkLabel: true,
    showSubtotal: model.showSubtotal,
    showMiniatureOnBasket: model.showMiniatureOnBasket,
    showExpandIcon: model.content_ShowExpandIcon,
    showThumbnails: model.content_ShowThumbnails,
    basketLinkLayout: model.basketLink_Layout,
    basketLinkThemeFontSize: model.basketLink_ThemeFontSize,
    contentThemeFontSize: model.content_ThemeFontSize,
    headingThemeFontSize: model.heading_ThemeFontSize,
    footerBasketLinkStyle: model.footer_BasketLinkStyle,
    footerCheckoutLinkStyle: model.footer_CheckoutLinkStyle,
    swapFooterLinksPosition: model.footer_SwapLinksPosition,
  }), [model]);
  const summaryId = `Summary_${id}`;

  return (
    <>
      <Helmet>
        <style>{`
          ${model.basketLink_FontFamily || model.basketLink_FontColor || model.basketLink_AllCaps ? `
            #${summaryId} .${styles.header},
            #${summaryId} .${styles.placeholder} {
              ${model.basketLink_FontFamily ? `font-family: ${model.basketLink_FontFamily}, sans-serif;` : ''}
              ${model.basketLink_FontColor ? `color: ${model.basketLink_FontColor};` : ''}
              ${model.basketLink_AllCaps ? 'text-transform: uppercase;' : ''}
            }
          ` : ''}
          ${model.basketLink_FontColor ? `
            #${summaryId} .dropdown-header {
              color: ${model.basketLink_FontColor};
            }
          ` : ''}
          #${summaryId} .${styles.header}.${styles.isEmpty} {
            color: ${model.basketLink_EmptyCartFontColor || '#999'};
          }
          #${summaryId} .${styles.icon} {
            color: ${model.basketLink_IconColor || '#cb2245'};
          }
          #${summaryId} .${styles.badge} {
            background-color: ${model.basketLink_BadgeBackgroundColor || '#e6e6e6'};
            color: ${model.basketLink_FontColor || 'inherit'};
          }
          #${summaryId} .dropdown.dropdown-opened,
          #${summaryId} .dropdown .dropdown-body {
            background-color: ${model.content_BackgroundColor || '#fff'};
            border-color: ${model.content_BorderColor || '#e6e6e6'};
            color: ${model.content_FontColor || '#000'};
          }
          #${summaryId} .dropdown-opened .dropdown-header,
          #${summaryId} .dropdown-opened .${styles.header} {
            color: ${model.content_FontColor || '#000'};
          }
          #${summaryId} .${styles.title} {
            ${model.heading_FontFamily ? `font-family: ${model.heading_FontFamily}, sans-serif;` : ''}
            color: ${model.heading_FontColor || 'inherit'};
            ${model.heading_AllCaps ? 'text-transform: uppercase;' : ''}
          }
          #${summaryId} .${styles.productTile},
          #${summaryId} .${styles.panelFooter} {
            border-color: ${model.content_SeparatorsColor || '#e6e6e6'};
          }
          ${model.footer_LinkFontColor ? `#${summaryId} .${styles.footerLink} {
            color: ${model.footer_LinkFontColor};
          }` : ''}
          ${model.footer_LinkHoverFontColor ? `
            html.${mousePointerClassName} #${summaryId} .${styles.footerLink}:hover {
              color: ${model.footer_LinkHoverFontColor};
            }
            ${mobileSafariMediaQuery} {
              #${summaryId} .${styles.footerLink}:hover {
                -webkit-tap-highlight-color: ${model.footer_LinkHoverFontColor};
              }
            }
          ` : ''}
        `}</style>
      </Helmet>
      <SummaryBase id={summaryId} isDesignerMode={isDesignerMode} options={options} />
    </>
  );
};

DesktopBasketSummaryBlock.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.shape({
    showMiniatureOnBasket: PropTypes.bool.isRequired,
    basketLink_Layout: PropTypes.number.isRequired,
    basketLink_FontFamily: PropTypes.string,
    basketLink_ThemeFontSize: PropTypes.string.isRequired,
    basketLink_AllCaps: PropTypes.bool.isRequired,
    basketLink_IconColor: PropTypes.string,
    basketLink_FontColor: PropTypes.string,
    basketLink_EmptyCartFontColor: PropTypes.string,
    basketLink_BadgeBackgroundColor: PropTypes.string,
    content_ShowExpandIcon: PropTypes.bool.isRequired,
    content_ThemeFontSize: PropTypes.string.isRequired,
    content_FontColor: PropTypes.string,
    content_BackgroundColor: PropTypes.string,
    content_BorderColor: PropTypes.string,
    content_SeparatorsColor: PropTypes.string,
    content_ShowThumbnails: PropTypes.bool.isRequired,
    heading_FontFamily: PropTypes.string,
    heading_ThemeFontSize: PropTypes.string.isRequired,
    heading_AllCaps: PropTypes.bool.isRequired,
    heading_FontColor: PropTypes.string,
    footer_BasketLinkStyle: PropTypes.number.isRequired,
    footer_CheckoutLinkStyle: PropTypes.number.isRequired,
    footer_SwapLinksPosition: PropTypes.bool.isRequired,
    footer_LinkFontColor: PropTypes.string,
    footer_LinkHoverFontColor: PropTypes.string,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

export default memo(DesktopBasketSummaryBlock);
