import { SalesAgreementStatus } from 'behavior/salesAgreements';

export const isProductOrderable = (
  line: AgreementLine,
  agreementStatus: SalesAgreementStatus,
  userCanViewUoms: boolean,
): boolean => {
  if (agreementStatus !== SalesAgreementStatus.Active)
    return false;

  const product = line.product;
  if (!product || !product.isOrderable)
    return false;

  const productUoms = product.uoms || [];
  const lineHasUom = !!line.uom?.id;
  // Agreement line without UOM that refers to the product with UOM is not valid.
  if (!lineHasUom && tryDefineNumber(line.quantities?.commitment) !== false && productUoms.length)
    return false;

  const lineUomId = line.uom?.id.toUpperCase();

  if (lineHasUom && !productUoms.some(uom => uom.id.toUpperCase() === lineUomId))
    return false;

  if (lineHasUom && lineUomId !== product.uom?.id.toUpperCase() && !userCanViewUoms)
    return false;

  const uom = productUoms.find(uom => uom.id.toUpperCase() === lineUomId);
  if (!isLineAvailableByQuantity(line, uom))
    return false;

  return true;
};

const isLineAvailableByQuantity = (line: AgreementLine, uom: Uom | null | undefined) => {
  if (!line.isMaxEnforced)
    return true;

  if (line.quantities.remaining === 0)
    return false;

  const qtyStep = tryDefineNumber(uom?.quantityStep);
  const qtyMax = tryDefineNumber(uom?.maximumQuantity);
  const qtyRemaining = tryDefineNumber(line.quantities.remaining);

  if (qtyStep !== false && (qtyRemaining !== false && (qtyStep > qtyRemaining) || qtyMax !== false && (qtyStep > qtyMax)))
    return false;

  return true;
};

const tryDefineNumber = (value: number | null | undefined): number | false => {
  if (value === null || value === undefined)
    return false;
  return value;
};

type Uom = {
  id: string;
  quantityStep?: number;
  maximumQuantity?: number;
};

type Product = {
  isOrderable: boolean;
  uom?: Uom;
  uoms?: Uom[];
};

type AgreementLine = {
  product?: Product | null;
  uom?: {
    id: string;
  };
  quantities: {
    commitment?: number;
    remaining?: number;
  };
  isMaxEnforced: boolean;
};
