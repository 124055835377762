import type { OrderingExperience } from './types';

export const ORDERING_EXPERIENCE_LOAD = 'ORDERING_EXPERIENCE/LOAD' as const;
export const loadOrderingExperience = () => ({
  type: ORDERING_EXPERIENCE_LOAD,
});

export const ORDERING_EXPERIENCE_LOADED = 'ORDERING_EXPERIENCE/LOADED' as const;
export const orderingExperienceLoaded = (orderingExperience: OrderingExperience) => ({
  type: ORDERING_EXPERIENCE_LOADED,
  payload: orderingExperience,
});

export type MyAccountAction = ReturnType<
  | typeof loadOrderingExperience
  | typeof orderingExperienceLoaded
>;
