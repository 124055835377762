import { map, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { initComponent } from '../helpers';
import { agreementDetailPageQuery } from './queries';
import { requestAgreementLinesAvailability } from './actions';
import { initSystemPageContent, loadSystemPageQuery } from '../system';
import { SalesAgreementStatus } from 'behavior/salesAgreements';

export default function salesAgreementHandler({ params: { id: agreementId, agreementLine, previewToken } }, state$, { api }) {
  if (previewToken) {
    return api.graphApi(loadSystemPageQuery('salesAgreement')).pipe(
      map(({ pages: { salesAgreement: page } }) => !page ? null : ({
        page: {
          ...page,
          component: PageComponentNames.SalesAgreement,
          agreement: {
            id: '',
            title: '',
            status: SalesAgreementStatus.Active,
            currency: { id: '' },
            effectiveDate: '-',
            expirationDate: '-',
            address: '-',
            lines: Array.from(Array(3)).map((_, index) => ({
              id: index.toString(),
              quantities: {},
              amounts: {},
              uom: { id: '' },
            })),
          },
        },
      })),
      initSystemPageContent(),
    );
  }

  return api.graphApi(agreementDetailPageQuery, { agreementId }).pipe(
    map(data => mapData(data, state$, agreementLine)),
    initComponent(PageComponentNames.SalesAgreement),
    filter(Boolean),
    initSystemPageContent(),
    map(data => ({
      ...data,
      action$: of(requestAgreementLinesAvailability(data.page.agreement)),
    })),
  );
}

function mapData(
  {
    pages: {
      salesAgreement: page,
    },
    basket,
  },
  state$,
  agreementLineId,
) {

  if (!page || !page.agreement)
    return null;

  for (const line of page.agreement.lines) {
    if (line.category) {
      if (line.category.type === 'NotExistingProductCategory') {
        delete line.category.type;
        line.category.exists = false;
      } else {
        line.category.exists = true;
      }
    }
  }

  page.agreement.expandedAgreementLineId = agreementLineId;

  const currentPageState = state$.value.page;
  if (currentPageState && currentPageState.agreement && currentPageState.agreement.id === page.agreement.id)
    page.search = currentPageState.search;

  return { ...page, basket };
}
