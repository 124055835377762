import { createReducer } from 'utils/redux';
import { InvoicesAndCreditNotesAction, InvoicesAndCreditNotesReceivedAction, INVOICES_WITH_CREDITNOTES_RECEIVED } from '../actions';
import { MultiTypeDocumentsCollection } from '../types';

type State = {
  docs: MultiTypeDocumentsCollection | null;
};

export default createReducer<State, InvoicesAndCreditNotesAction>(null as any, {
  [INVOICES_WITH_CREDITNOTES_RECEIVED]: onDocumentsReceived,
});

function onDocumentsReceived(state: State, action: InvoicesAndCreditNotesReceivedAction) {
  const { documents } = action.payload;

  return {
    ...state,
    docs: documents,
  };
}