export default Object.freeze([
  {
    columns: [
      {
        colspan: {
          lg: 8,
          md: 8,
          sm: 8,
        },
        contentBlocks: [
          {
            id: '6c8mbdcem',
            name: 'CallUsMessage',
          },
        ],
        id: 'l5hou88v7',
      },
      {
        colspan: {
          lg: 4,
          md: 4,
          sm: 4,
        },
        contentBlocks: [
          {
            id: 'gcy46vu9x',
            model: {
              toggle_Presentation: 0,
              toggle_FontSize: 'Regular',
              toggle_AllCaps: false,
              content_ShowFlags: true,
              content_AllCaps: false,
              content_ShowExpandIcon: true,
              content_ItemFontSize: 'Regular',
            },
            name: 'DesktopLanguageSelector',
          },
          {
            id: 'mfuntmx97',
            model: {
              toggle_Presentation: 0,
              toggle_ThemeFontSize: 'Regular',
              toggle_AllCaps: false,
              content_ShowExpandIcon: true,
              content_ThemeFontSize: 'Regular',
            },
            name: 'DesktopAccountMenu',
          },
        ],
        contentOrientation: 'HORIZONTAL',
        horizontalAlignment: 'RIGHT',
        id: 'hmek0g6ww',
      },
    ],
    id: 'y49rf2u95',
    spacing: {
      margin: null,
      padding: null,
    },
    verticalAlignment: 'MIDDLE',
  },
  {
    columns: [
      {
        colspan: {
          lg: 3,
          md: 3,
          sm: 3,
        },
        contentBlocks: [
          {
            id: 'ewi2x1tql',
            name: 'Logo',
          },
        ],
        contentOrientation: 'HORIZONTAL',
        horizontalAlignment: 'LEFT',
        id: 't9vz26j0t',
      },
      {
        colspan: {
          lg: 6,
          md: 6,
          sm: 6,
        },
        contentBlocks: [
          {
            id: '4tjd3gwd3',
            model: {
              box_BorderStyle: 1,
              box_BorderWidth: '1px',
              box_CornerRadius: '2px',
              box_ThemeFontSize: 'Regular',
              button_Layout: 0,
              button_ThemeFontSize: 'Regular',
              button_CornerRadius: '0px 2px 2px 0px',
              suggestions_ShowThumbnails: true,
              suggestions_ThemeFontSize: 'Regular',
            },
            name: 'SearchBar',
          },
        ],
        id: 'bktszl9uo',
      },
      {
        colspan: {
          lg: 3,
          md: 3,
          sm: 3,
        },
        contentBlocks: [
          {
            id: 'ys5lw1klx',
            name: 'ProductSuggestions',
          },
          {
            id: 'xlk1wl5sy',
            name: 'Spacer',
          },
          {
            id: '27gozsfda',
            model: {
              showMiniatureOnBasket: false,
              showSubtotal: false,
              basketLink_Layout: 0,
              basketLink_ThemeFontSize: 'Regular',
              basketLink_AllCaps: true,
              content_ShowExpandIcon: false,
              content_ThemeFontSize: 'Regular',
              content_ShowThumbnails: true,
              heading_ThemeFontSize: 'Heading4',
              heading_AllCaps: true,
              footer_BasketLinkStyle: 0,
              footer_CheckoutLinkStyle: 2,
              footer_SwapLinksPosition: false,
            },
            name: 'DesktopBasketSummary',
          },
        ],
        contentOrientation: 'HORIZONTAL',
        id: '97b6u74e6',
      },
    ],
    id: 'fc1rueo9h',
    spacing: {
      margin: '20px 0px 19px',
      padding: null,
    },
    verticalAlignment: 'MIDDLE',
  },
  {
    background: {
      color: '#F7F7F7',
    },
    columns: [
      {
        contentBlocks: [
          {
            id: '3mkpytrog',
            model: {
              topLevel_AllCaps: false,
              topLevel_ShowActiveItemUnderline: true,
              subLevel_AllCaps: false,
              subLevel_ShowArrowInFrontOfSubItem: true,
            },
            name: 'DesktopCascadingMainMenu',
          },
        ],
        id: 'oyo8xde4q',
      },
    ],
    id: 'z83fjzs8n',
    spacing: {
      margin: null,
      padding: null,
    },
    verticalAlignment: 'MIDDLE',
  },
]);
