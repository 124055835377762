import styles from './Summary.module.scss';
import { memo, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { joinClasses } from 'utils/helpers';
import { Link } from 'components/primitives/links';
import { SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { useLayoutShifter, useExtras } from 'utils/layout';
import { CartSummaryIcon } from 'components/primitives/icons';
import { getThemeFontSizeClassName } from 'components/theme';
import { BasketLinkLayoutOption } from './constants';

const Header = ({ totalCount, showFixed, options }) => {
  const extras = useExtras();
  const ref = useRef();
  const { topFixedElementsHeight } = useLayoutShifter();

  useEffect(() => {
    if (!showFixed)
      return;

    // Updates basket link top style property depending on layout top shift value.
    ref.current.style.top = `${topFixedElementsHeight}px`;
  }, [showFixed, topFixedElementsHeight]);

  let iconWithText;
  switch (options.basketLinkLayout) {
    case BasketLinkLayoutOption.TextLeftOfIcon:
      iconWithText = (
        <>
          <span className={styles.summaryNumber}>{totalCount}</span>
          {options.showBasketLinkLabel && <span> <SimpleText textKey="ShoppingBasket_QuantityPostfix" /></span>}
          <CartSummaryIcon className={joinClasses(styles.icon, styles.textLeft)} aria-hidden />
        </>
      );
      break;
    case BasketLinkLayoutOption.TextBelowOfIcon:
      iconWithText = (
        <>
          <CartSummaryIcon className={joinClasses(styles.icon, styles.noMargin)} aria-hidden />
          <span className={styles.textBelowIcon}>
            <span className={styles.summaryNumber}>{totalCount}</span>
            {options.showBasketLinkLabel && <span> <SimpleText textKey="ShoppingBasket_QuantityPostfix" /></span>}
          </span>
        </>
      );
      break;
    case BasketLinkLayoutOption.ShowNumberOfProductsAsBadge:
      iconWithText = (
        <span className={styles.badgeContainer}>
          <CartSummaryIcon className={joinClasses(styles.icon, styles.noMargin)} aria-hidden />
          {!!totalCount && <span className={`${styles.summaryNumber} ${styles.badge}`}>{totalCount}</span>}
        </span>
      );
      break;
    default:
      iconWithText = (
        <>
          <CartSummaryIcon className={styles.icon} aria-hidden />
          <span className={styles.summaryNumber}>{totalCount}</span>
          {options.showBasketLinkLabel && <span> <SimpleText textKey="ShoppingBasket_QuantityPostfix" /></span>}
        </>
      );
      break;
  }

  const basketLink = (
    <Link
      className={
        joinClasses(
          styles.header,
          !totalCount && styles.isEmpty,
          !options.showBasketLinkLabel && styles.withoutLabel,
          showFixed && styles.fixed,
          getThemeFontSizeClassName(options.basketLinkThemeFontSize),
        )
      }
      to={routesBuilder.forBasket}
      ref={ref}
    >
      {iconWithText}
    </Link>
  );

  return (
    <>
      {showFixed && extras ? ReactDOM.createPortal(basketLink, extras) : basketLink}
      <span
        className={joinClasses(
          styles.placeholder,
          showFixed && styles.fixed,
          getThemeFontSizeClassName(options.basketLinkThemeFontSize),
        )}
        aria-hidden
      >
        <CartSummaryIcon className={styles.icon} aria-hidden />
        <span className={styles.summaryNumber}>{totalCount}</span>
      </span>
    </>
  );
};

Header.propTypes = {
  totalCount: PropTypes.number,
  showFixed: PropTypes.bool,
  options: PropTypes.shape({
    basketLinkLayout: PropTypes.number.isRequired,
    showBasketLinkLabel: PropTypes.bool.isRequired,
    basketLinkThemeFontSize: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(Header);
