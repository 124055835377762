import { Observable, of, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { normalizeAddonId, getAddonSrc } from './helpers';
import type { Api } from 'utils/api';
import type { AddonFactory, AddonLoader } from './types';

type CacheEntry = {
  factory: AddonFactory;
  hash: string | null;
};

type LoadedAddon = [id: string, hash: string, factory: AddonFactory];

export function nodeLoader(origin: string, headers: HeadersInit, reactiveFetch: Api['fetch'], cache: Map<string, CacheEntry>) {
  function materialize(src: string, hash: string | null): AddonFactory | undefined {
    let result: AddonFactory | undefined = undefined;
    const registerAddon = (addonId: string, _ignoredHash: string | null, addonFactory: AddonFactory) => {
      result = addonFactory;

      if (!isDevelopmentAddon(hash))
        cache.set(createAddonCacheKey(addonId), { factory: result, hash });
    };

    /* eslint-disable-next-line no-new-func */
    Function('global', 'process', 'registerAddon', src)(undefined, undefined, registerAddon);
    return result;
  }

  function isDevelopmentAddon(hash: string | null) {
    return !hash;
  }

  function createAddonCacheKey(id: string) {
    return `addon_${normalizeAddonId(id)}`;
  }

  function getAddonFactoryFromCache(id: string, hash: string | null) {
    if (isDevelopmentAddon(hash))
      return;

    const addon = cache.get(createAddonCacheKey(id));
    if (addon?.hash !== hash)
      return;

    return addon.factory;
  }

  return (addonId: string, addonHash: string | null) => {
    const addonFactory = getAddonFactoryFromCache(addonId, addonHash);
    if (addonFactory)
      return of(addonFactory);

    const src = getAddonSrc(addonId, addonHash, true);
    return reactiveFetch<string>(origin + src, { headers, cache: 'no-store' }).pipe(
      map(src => materialize(src, addonHash)),
    );
  };
}

export function browserLoader(requestTracker: OperatorFunction<AddonFactory, AddonFactory>, addons?: LoadedAddon[]): AddonLoader {
  const addonFactories = new Map<string, { hash: string | null; factory: AddonFactory }>();
  const head = document.getElementsByTagName('head')[0];

  if (addons) {
    for (const [id, hash, factory] of addons)
      addonFactories.set(normalizeAddonId(id), { hash, factory });
  }

  Object.defineProperty(window, 'registerAddon', {
    value(addonId: string, hash: string | null, factory: AddonFactory): void {
      addonFactories.set(normalizeAddonId(addonId), { hash, factory });
    },
    writable: false,
  });

  return (addonId, addonHash) => {
    const loadedFactory = getAddonFactory();
    if (loadedFactory)
      return of(loadedFactory);

    return new Observable<AddonFactory>(observer => {
      const script = document.createElement('script');
      script.async = true;
      script.src = getAddonSrc(addonId, addonHash);

      let timeout: number | undefined = undefined;
      const onComplete = (event?: { type: 'load' | 'timeout'; target?: HTMLScriptElement }) => {
        // avoid mem leaks in IE.
        script.onerror = script.onload = null;
        window.clearTimeout(timeout);

        const factory = getAddonFactory();
        if (factory) {
          observer.next(factory);
          observer.complete();
        }
        else {
          const errorType = event
            && (event.type === 'load' ? 'missing' : event.type);
          const realSrc = event && event.target && event.target.src;

          const error = {
            message: `Loading addon ${addonId} failed.\n(${errorType} : ${realSrc})`,
            type: errorType,
            request: realSrc,
          };

          observer.error(error);
        }
      };

      timeout = window.setTimeout(() => onComplete({ type: 'timeout', target: script }), 120000);
      script.onerror = script.onload = onComplete as any;

      head.appendChild(script);
    }).pipe(
      requestTracker,
    );

    function getAddonFactory() {
      const addonFactory = addonFactories.get(addonId);
      if (addonFactory && addonFactory.hash === addonHash && typeof (addonFactory.factory) === 'function')
        return addonFactory.factory;

      return undefined;
    }
  };
}
