import type { ReorderResult } from 'behavior/documents';
import type { DocumentType, EditResult } from 'behavior/documents';
import type {
  DocumentLines,
  QuoteStock,
  PromotionResult,
  AuthorizationActionInput,
  AuthorizationInfo,
  PromotionErrorResult,
} from './types';
import { OrderAuthorizationAction, OrderAuthorizationActionResultDetailedStatus } from './constants';

export const DOCUMENT_LINES_REQUESTED = 'DOCUMENT_LINES_REQUESTED' as const;
export const loadLines = (id: string, documentType: DocumentType, orderId: string) => ({
  type: DOCUMENT_LINES_REQUESTED,
  payload: { id, documentType, orderId },
});

export const DOCUMENT_LINES_RECEIVED = 'DOCUMENT_LINES_RECEIVED' as const;
export const documentLinesReceived = (documentLines: DocumentLines) => ({
  type: DOCUMENT_LINES_RECEIVED,
  payload: { documentLines },
});

export const DOCUMENT_REORDER_REQUESTED = 'DOCUMENT_REORDER_REQUESTED' as const;
export const reorder = (id: string, orderId: string, forceBasketClean: boolean) => ({
  type: DOCUMENT_REORDER_REQUESTED,
  payload: { id, orderId, forceBasketClean },
});

export const DOCUMENT_REORDER_RECEIVED = 'DOCUMENT_REORDER_RECEIVED' as const;
export const reorderReceived = (result: ReorderResult | null) => ({
  type: DOCUMENT_REORDER_RECEIVED,
  payload: { result },
});

export const QUOTE_STOCK_REQUESTED = 'QUOTE_STOCK_REQUESTED' as const;
export const loadQuoteStock = (id: string) => ({
  type: QUOTE_STOCK_REQUESTED,
  payload: { id },
});

export const QUOTE_STOCK_RECEIVED = 'QUOTE_STOCK_RECEIVED' as const;
export const quoteStockReceived = (quote: QuoteStock | undefined) => ({
  type: QUOTE_STOCK_RECEIVED,
  payload: { quote },
});

export const QUOTE_PROMOTION_REQUESTED = 'QUOTE_PROMOTION_REQUESTED' as const;
export const promoteQuote = (id: string, ignoreCreditLimitWarnings: boolean) => ({
  type: QUOTE_PROMOTION_REQUESTED,
  payload: { id, ignoreCreditLimitWarnings },
});

export const QUOTE_PROMOTION_RESULT_RECEIVED = 'QUOTE_PROMOTION_RESULT_RECEIVED' as const;
export const quotePromotionResultReceived = (result: PromotionResult | PromotionErrorResult) => ({
  type: QUOTE_PROMOTION_RESULT_RECEIVED,
  payload: result,
});

export const DOCUMENT_EDIT_REQUESTED = 'DOCUMENT_EDIT_REQUESTED' as const;
export const edit = (id: string, forceBasketClean = false) => ({
  type: DOCUMENT_EDIT_REQUESTED,
  payload: { id, forceBasketClean },
});

export const DOCUMENT_EDIT_RECEIVED = 'DOCUMENT_EDIT_RECEIVED' as const;
export const editReceived = (result: EditResult | null) => ({
  type: DOCUMENT_EDIT_RECEIVED,
  payload: { result },
});

export const ORDER_AUTHORIZATION_ACTION_REQUESTED = 'ORDER_AUTHORIZATION_ACTION_REQUESTED' as const;
const authorizeOrder = (actionType: OrderAuthorizationAction, input: AuthorizationActionInput) => ({
  type: ORDER_AUTHORIZATION_ACTION_REQUESTED,
  payload: { actionType, input },
});

export const approveOrder = (orderId: string) => authorizeOrder(OrderAuthorizationAction.Approve, { orderId });
export const declineOrder = (orderId: string, feedbackMessage: string) => authorizeOrder(OrderAuthorizationAction.Decline, {
  orderId,
  feedbackMessage,
});
export const cancelOrderAuthorization = (orderId: string) => authorizeOrder(OrderAuthorizationAction.Cancel, { orderId });

export const ORDER_AUTHORIZATION_RESULT_RECEIVED = 'ORDER_AUTHORIZATION_RESULT_RECEIVED' as const;
export const orderAuthResultReceived = (authorizationActionResultStatus: OrderAuthorizationActionResultDetailedStatus, authorizationInfo: AuthorizationInfo) => ({
  type: ORDER_AUTHORIZATION_RESULT_RECEIVED,
  payload: { authorizationActionResultStatus, authorizationInfo },
});

export const SET_INVOICE_PAYMENT_AMOUNT = 'INVOICE_PAYMENT/SET_AMOUNT' as const;
export const setInvoicePaymentAmount = (paymentAmount: number) => ({
  type: SET_INVOICE_PAYMENT_AMOUNT,
  payload: { paymentAmount },
});

export type DocumentLinesReceived = ReturnType<typeof documentLinesReceived>;
export type ReorderReceived = ReturnType<typeof reorderReceived>;
export type QuoteStockReceived = ReturnType<typeof quoteStockReceived>;
export type QuotePromotionResultReceived = ReturnType<typeof quotePromotionResultReceived>;
export type EditReceived = ReturnType<typeof editReceived>;
export type OrderAuthResultReceived = ReturnType<typeof orderAuthResultReceived>;
export type SetInvoicePaymentAmount = ReturnType<typeof setInvoicePaymentAmount>;

export type DocumentAction =
  | DocumentLinesReceived
  | ReorderReceived
  | QuoteStockReceived
  | QuotePromotionResultReceived
  | EditReceived
  | OrderAuthResultReceived
  | SetInvoicePaymentAmount
  | ReturnType<
    | typeof reorder
    | typeof edit
    | typeof loadLines
    | typeof reorder
    | typeof reorderReceived
    | typeof loadQuoteStock
    | typeof quoteStockReceived
    | typeof promoteQuote
    | typeof quotePromotionResultReceived
    | typeof edit
    | typeof editReceived
    | typeof authorizeOrder
    | typeof approveOrder
    | typeof declineOrder
    | typeof cancelOrderAuthorization
    | typeof orderAuthResultReceived
  >;
