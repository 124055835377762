import { loadEditProfilePageQuery } from './queries';
import { PageComponentNames } from 'behavior/pages';
import { map } from 'rxjs/operators';
import { getBackTo } from '../helpers';
import { RouteName } from 'routes';
import { initSystemPageContent } from '../system';

export default (routeData, state$, { api }) => {
  return api.graphApi(loadEditProfilePageQuery).pipe(
    map(({ pages, profile }) => {
      const page = pages.editProfile;
      if (!page)
        return null;

      page.templateFields = profile.profileFields.filter(shouldIncludeField);
      page.component = PageComponentNames.EditProfile;

      page.backTo = getBackTo(state$, [
        RouteName.EditProfile,
      ], routeData.params && routeData.params.language);

      return { page };
    }),
    initSystemPageContent(),
  );
};

const shouldIncludeField = field => {
  return field.type !== 'ReadOnlyTemplateField' || field.displayValue;
};
