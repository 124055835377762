import styles from '../MainNav.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ComponentGroup } from 'behavior/navigation/constants';
import DesktopNavBase from './DesktopNavBase';
import NavMenuContext from '../NavMenuContext';
import { Helmet } from 'react-helmet';
import { mobileSafariMediaQuery } from 'utils/detections';
import { mousePointerClassName } from 'components/detection';

const MultiColumnMainMenuBlock = ({ model, id, isDesignerMode }) => {
  const context = useMemo(() => ({
    componentGroup: ComponentGroup.MultiColumnMainMenu,
    showViewMoreLink: model.secondLevel_ShowViewMoreLink,
  }), []);
  const navId = `MainNav_${id}`;
  
  return (
    <>
      <Helmet>
        <style>{`
          #${navId} .${styles.hypTopLvl} {
            font-size: ${model.topLevel_FontSize || '20px'};
            color: ${model.topLevel_FontColor || '#000'};
            ${model.topLevel_FontFamily ? `font-family: ${model.topLevel_FontFamily}, sans-serif;` : ''}
            ${model.topLevel_AllCaps ? 'text-transform: uppercase;' : ''}
          }
          html.${mousePointerClassName} #${navId} .${styles.hypTopLvl}:hover {
            color: ${model.topLevel_HoverFontColor || '#000'};
          }
          ${mobileSafariMediaQuery} {
            #${navId} .${styles.hypTopLvl}:hover {
              -webkit-tap-highlight-color: ${model.topLevel_HoverFontColor || '#000'};
            }
          }
          #${navId} .${styles.navListRoot}.nav-is-hovered .${styles.hypTopLvl} {
            color: ${model.topLevel_NonHoveredItemFontColor || 'grey'};
          }
          #${navId} .${styles.navItemRoot} {
            height: ${model.topLevel_ItemHeight || '4.6em'};
          }
          ${model.topLevel_ShowActiveItemUnderline ? `
            #${navId} .${styles.navItemRoot}.${styles.active} .${styles.hypTopLvl} > span:after {
              content: '';
              background-color: ${model.topLevel_ActiveItemUnderlineColor || '#cb2245'};
            }
          ` : ''}
          #${navId} .${styles.navItemRoot}.hover .${styles.hypTopLvl},
          #${navId} .${styles.subItems} {
            background-color: ${model.topLevel_ExpandedMenuBackgroundColor || '#fff'};
            border-color: ${model.topLevel_ExpandedMenuBorderColor || '#e6e6e6'};
          }
          #${navId} .${styles.hypSecondLvl} {
            ${model.secondLevel_FontFamily ? `font-family: ${model.secondLevel_FontFamily}, sans-serif;` : ''}
            font-size: ${model.secondLevel_FontSize || '1.4em'};
            color: ${model.secondLevel_FontColor || '#000'};
            ${model.secondLevel_AllCaps ? 'text-transform: uppercase;' : ''}
          }
          html.${mousePointerClassName} #${navId} .${styles.hypSecondLvl}:hover {
            color: ${model.secondLevel_HoverFontColor || '#000'};
          }
          ${mobileSafariMediaQuery} {
            #${navId} .${styles.hypSecondLvl}:hover {
              -webkit-tap-highlight-color: ${model.secondLevel_HoverFontColor || '#000'};
            }
          }
          #${navId}.${styles.multicolumn} .${styles.navItemBlock} {
            border-right-color: ${model.secondLevel_SeparatorLineColor || '#e6e6e6'};
          }
          ${model.secondLevel_ShowViewMoreLink ? `
            #${navId} .${styles.hyp} {
              color: ${model.secondLevel_ViewMoreLinkFontColor || '#1f7bc9'};
            }
            html.${mousePointerClassName} #${navId} .${styles.hyp}:hover {
              color: ${model.secondLevel_ViewMoreLinkHoverFontColor || '#2289e3'};
            }
            ${mobileSafariMediaQuery} {
              #${navId} .${styles.hyp}:hover {
                -webkit-tap-highlight-color: ${model.secondLevel_ViewMoreLinkHoverFontColor || '#2289e3'};
              }
            }
          ` : ''}
          #${navId} .${styles.hypDefault} {
            font-family: ${model.thirdLevel_FontFamily || '"Open Sans"'}, sans-serif;
            font-size: ${model.thirdLevel_FontSize || '1em'};
            color: ${model.thirdLevel_FontColor || '#333'};
            padding-left: 0;
            ${model.thirdLevel_AllCaps ? 'text-transform: uppercase;' : ''}
          }
          html.${mousePointerClassName} #${navId} .${styles.hypDefault}:hover {
            color: ${model.thirdLevel_HoverFontColor || '#333'};
          }
          ${mobileSafariMediaQuery} {
            #${navId} .${styles.hypDefault}:hover {
              -webkit-tap-highlight-color: ${model.thirdLevel_HoverFontColor || '#333'};
            }
          }
          ${model.thirdLevel_ShowArrowInFrontOfSubItem ? `
            #${navId} .${styles.hyp},
            #${navId} .${styles.hypDefault} {
              padding-left: 0.55em;
            }
            #${navId} .${styles.hyp}:before,
            #${navId} .${styles.hypDefault}:before {
              content: '› ';
            }
          ` : ''}
        `}</style>
      </Helmet>
      <NavMenuContext.Provider value={context}>
        <DesktopNavBase isDesignerMode={isDesignerMode} id={navId} />
      </NavMenuContext.Provider>
    </>
  );
};

MultiColumnMainMenuBlock.propTypes = {
  model: PropTypes.shape({
    topLevel_FontFamily: PropTypes.string,
    topLevel_FontSize: PropTypes.string,
    topLevel_AllCaps: PropTypes.bool.isRequired,
    topLevel_FontColor: PropTypes.string,
    topLevel_HoverFontColor: PropTypes.string,
    topLevel_NonHoveredItemFontColor: PropTypes.string,
    topLevel_ActiveItemUnderlineColor: PropTypes.string,
    topLevel_ShowActiveItemUnderline: PropTypes.bool.isRequired,
    topLevel_ExpandedMenuBackgroundColor: PropTypes.string,
    topLevel_ExpandedMenuBorderColor: PropTypes.string,
    topLevel_ItemHeight: PropTypes.string,
    secondLevel_FontFamily: PropTypes.string,
    secondLevel_FontSize: PropTypes.string,
    secondLevel_AllCaps: PropTypes.bool.isRequired,
    secondLevel_FontColor: PropTypes.string,
    secondLevel_HoverFontColor: PropTypes.string,
    secondLevel_SeparatorLineColor: PropTypes.string,
    secondLevel_ShowViewMoreLink: PropTypes.bool.isRequired,
    secondLevel_ViewMoreLinkFontColor: PropTypes.string,
    secondLevel_ViewMoreLinkHoverFontColor: PropTypes.string,
    thirdLevel_FontFamily: PropTypes.string,
    thirdLevel_FontSize: PropTypes.string,
    thirdLevel_AllCaps: PropTypes.bool.isRequired,
    thirdLevel_FontColor: PropTypes.string,
    thirdLevel_HoverFontColor: PropTypes.string,
    thirdLevel_ShowArrowInFrontOfSubItem: PropTypes.bool.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  isDesignerMode: PropTypes.bool,
};

export default MultiColumnMainMenuBlock;
