import styles from './LinkMenu.module.scss';
import PropTypes from 'prop-types';
import NavItem from './NavItem';
import { useLoadNavigation, useAriaLabel } from '../hooks';
import { ComponentGroup } from 'behavior/navigation/constants';
import { Helmet } from 'react-helmet';
import { getThemeFontSizeClassName } from 'components/theme';
import { joinClasses } from 'utils/helpers';

const LinkMenuBLock = ({ id, model }) => {
  const [items, ready] = useLoadNavigation(ComponentGroup.LinkMenu, model.navigationGroupCode);
  const ariaLabel = useAriaLabel(model.navigationGroupCode);

  if (!ready)
    return null;

  const hasSubLevel = items.some(item => item.children?.length);
  const topLevelClassName = joinClasses(
    styles.topLevel,
    hasSubLevel && styles.columns,
    getThemeFontSizeClassName(hasSubLevel ? model.headingLinks_ThemeFontSize : model.links_ThemeFontSize),
  );
  const childrenClassName = getThemeFontSizeClassName(model.links_ThemeFontSize);

  return (
    <>
      <Helmet>
        <style>{`
          #LinkMenu_${id} a {
            font-family: ${model.links_FontFamily || 'inherit'};
            color: ${model.links_FontColor || 'inherit'};
            ${model.links_AllCaps ? 'text-transform: uppercase;' : ''}
          }
          ${model.links_HoverFontColor ? `
            #LinkMenu_${id} a:hover {
              color: ${model.links_HoverFontColor} !important;
            }
          ` : ''}
          #LinkMenu_${id} .${styles.columns} > li {
            flex-basis: ${100 / Math.min(items.length, 12)}%;
          }
          #LinkMenu_${id} .${styles.columns} > li > a {
            font-family: ${model.headingLinks_FontFamily || 'inherit'};
            color: ${model.headingLinks_FontColor || 'inherit'};
            ${model.headingLinks_AllCaps ? 'text-transform: uppercase;' : ''}
          }
          ${model.headingLinks_HoverFontColor ? `
            #LinkMenu_${id} .${styles.columns} > li > a:hover {
              color: ${model.headingLinks_HoverFontColor} !important;
            }
          ` : ''}
          ${model.links_ShowArrowInFront ? `
            #LinkMenu_${id} a:only-child:before {
              content: '› ';
            }
            #LinkMenu_${id} .${styles.columns} > li > a:before {
              content: none;
            }
          ` : ''}
        `}</style>
      </Helmet>
      <nav aria-label={ariaLabel} className={styles.container} id={`LinkMenu_${id}`}>
        <ul className={topLevelClassName}>
          {items.map((item, index) => <NavItem key={index} item={item} childrenClassName={childrenClassName} />)}
        </ul>
      </nav>
    </>
  );
};

LinkMenuBLock.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.shape({
    navigationGroupCode: PropTypes.string.isRequired,
    headingLinks_FontFamily: PropTypes.string,
    headingLinks_ThemeFontSize: PropTypes.string.isRequired,
    headingLinks_AllCaps: PropTypes.bool.isRequired,
    headingLinks_FontColor: PropTypes.string,
    headingLinks_HoverFontColor: PropTypes.string,
    links_FontFamily: PropTypes.string,
    links_ThemeFontSize: PropTypes.string.isRequired,
    links_AllCaps: PropTypes.bool.isRequired,
    links_FontColor: PropTypes.string,
    links_HoverFontColor: PropTypes.string,
    links_ShowArrowInFront: PropTypes.bool.isRequired,
  }).isRequired,
};

export default LinkMenuBLock;
