import styles from './OpenerButton.module.scss';
import { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { MenuOpenIcon } from 'components/primitives/icons';

const OpenerButton = forwardRef(({ className, ...props }, ref) => (
  <button className={`${styles.btnMenuOpener} ${className || ''}`} ref={ref} {...props}>
    <MenuOpenIcon className={styles.icon} />
  </button>
));

OpenerButton.propTypes = {
  className: PropTypes.string,
};

export default memo(OpenerButton);